@charset "UTF-8";
/*normal*/
/*@function vw($px, $width: 375) {
    @return (strip-unit($px) / strip-unit($width)) * 100vw;
}*/
.sec-About_head {
  background: url("../../assets/img/about/bg_about_head.jpg") center center/cover no-repeat;
  height: 61.49341vw; }
  @media screen and (max-width: 499px) {
    .sec-About_head {
      height: auto; } }
  .sec-About_head .content-head {
    position: relative; }
    .sec-About_head .content-head:before {
      content: '';
      width: 7.32064vw;
      height: 3.66032vw;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      background: url("../../assets/img/about/bg_triangle_01.png") center top/cover no-repeat; }
    .sec-About_head .content-head .title {
      width: 28.98975vw;
      margin: 0 auto;
      padding: 8.78477vw 0 7.32064vw; }
      @media screen and (max-width: 499px) {
        .sec-About_head .content-head .title {
          width: 66.66667vw;
          padding: 16vw 0 13.33333vw; } }
    .sec-About_head .content-head .lead-title-wrap {
      text-align: center; }
    .sec-About_head .content-head .lead-title {
      font-size: 1.46413vw;
      letter-spacing: 0.3em;
      padding: 0 7.10102vw 0 7.54026vw;
      color: #f7931e;
      position: relative;
      display: inline-block; }
      .sec-About_head .content-head .lead-title::before, .sec-About_head .content-head .lead-title::after {
        content: '';
        width: 5.6369vw;
        height: 1px;
        background: #f7931e;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto 0; }
      .sec-About_head .content-head .lead-title::before {
        left: 0; }
      .sec-About_head .content-head .lead-title::after {
        right: 0; }
      @media screen and (max-width: 499px) {
        .sec-About_head .content-head .lead-title {
          font-size: 4vw;
          letter-spacing: 0.15em; } }
    .sec-About_head .content-head .lead {
      font-size: 2.306vw;
      text-align: center;
      letter-spacing: 0.15em;
      padding-left: 0.15em;
      line-height: 2;
      margin-top: 2.92826vw; }
      @media screen and (max-width: 499px) {
        .sec-About_head .content-head .lead {
          font-size: 3.73333vw;
          letter-spacing: 0.05em;
          padding-left: 0.05em;
          margin-top: 5.33333vw; } }
    .sec-About_head .content-head .about-list-wrap {
      background: linear-gradient(to right, rgba(247, 147, 30, 0) 0%, rgba(247, 147, 30, 0.5) 5%, #f7931e 15%, #f7931e 85%, rgba(247, 147, 30, 0.5) 95%, rgba(247, 147, 30, 0) 100%);
      padding: 2.19619vw 6.22255vw;
      width: 85.35871vw;
      margin: 2.56223vw auto 0; }
      @media screen and (max-width: 499px) {
        .sec-About_head .content-head .about-list-wrap {
          background: linear-gradient(to right, rgba(247, 147, 30, 0) 0%, rgba(247, 147, 30, 0.5) 10%, #f7931e 25%, #f7931e 75%, rgba(247, 147, 30, 0.5) 90%, rgba(247, 147, 30, 0) 100%);
          margin: 5.33333vw auto 0; } }
    .sec-About_head .content-head .about-list {
      display: flex; }
      .sec-About_head .content-head .about-list li {
        font-size: 1.46413vw;
        text-align: center;
        padding: 0.21962vw 0; }
        @media screen and (min-width: 500px) {
          .sec-About_head .content-head .about-list li + li {
            border-left: 1px solid #fff; } }
        .sec-About_head .content-head .about-list li a {
          color: #fff;
          width: 24.15813vw;
          display: block;
          letter-spacing: 0.1em; }
          .sec-About_head .content-head .about-list li a span {
            font-size: 0.95168vw;
            display: block;
            letter-spacing: 0.1em;
            margin-top: 0.58565vw; }
      @media screen and (max-width: 499px) {
        .sec-About_head .content-head .about-list {
          display: block;
          padding: 2.66667vw 0; }
          .sec-About_head .content-head .about-list li {
            font-size: 4vw;
            width: 60%;
            margin-right: auto;
            margin-left: auto; }
            .sec-About_head .content-head .about-list li + li {
              margin-top: 2.66667vw;
              padding-top: 2.66667vw;
              border-top: 1px solid rgba(255, 255, 255, 0.5); }
            .sec-About_head .content-head .about-list li a {
              width: 100%; }
              .sec-About_head .content-head .about-list li a span {
                font-size: 2.66667vw;
                margin-top: 1.6vw; } }
  .sec-About_head .under-arrow {
    width: 4.46559vw;
    margin: 6.95461vw auto 0; }

.sec-About .content-box {
  width: 100%;
  height: 43.92387vw;
  border: 0.73206vw solid #f0f0f0;
  padding: 6.00293vw 7.32064vw 10.2489vw;
  position: relative; }
  .sec-About .content-box::before, .sec-About .content-box::after {
    content: '';
    width: 1px;
    height: 7.32064vw;
    background: #f7931e;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto; }
  .sec-About .content-box::before {
    top: -4.02635vw; }
  .sec-About .content-box::after {
    bottom: -4.02635vw; }
  @media screen and (max-width: 499px) {
    .sec-About .content-box {
      border: 1.33333vw solid #f0f0f0; } }

.sec-About .title {
  font-size: 2.41581vw;
  letter-spacing: 0.1em;
  text-align: center; }
  .sec-About .title span {
    display: block;
    color: #f7931e;
    font-size: 1.46413vw;
    letter-spacing: 0.1em;
    margin-top: 1.02489vw; }
  @media screen and (max-width: 499px) {
    .sec-About .title {
      font-size: 4.26667vw; }
      .sec-About .title span {
        font-size: 2.66667vw;
        margin-top: 1.33333vw; } }

.sec-About_01 {
  position: relative; }
  .sec-About_01::before {
    content: '';
    width: 26.28111vw;
    height: 26.57394vw;
    position: absolute;
    top: -20.64422vw;
    left: -9.80966vw;
    background: url("../../assets/img/about/bg_triangle_02.png") center top/cover no-repeat; }
  .sec-About_01::after {
    content: '';
    width: 13.39678vw;
    height: 13.54319vw;
    position: absolute;
    top: -21.96193vw;
    right: 0;
    background: url("../../assets/img/about/bg_triangle_03.png") center top/cover no-repeat; }
  .sec-About_01 .content-box {
    height: auto;
    padding: 6.00293vw 7.32064vw 10.2489vw;
    background: #fff; }
    .sec-About_01 .content-box .lead {
      font-size: 1.68375vw;
      letter-spacing: 0.1em;
      text-align: center;
      margin-top: 4.75842vw; }
    .sec-About_01 .content-box .image-area {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 5.49048vw; }
      .sec-About_01 .content-box .image-area figure {
        display: block;
        width: 12.81113vw; }
    @media screen and (max-width: 499px) {
      .sec-About_01 .content-box {
        padding: 10.66667vw 8vw 9.33333vw; }
        .sec-About_01 .content-box .lead {
          font-size: 3.46667vw;
          letter-spacing: 0.05em;
          text-align: center;
          margin-top: 5.33333vw;
          line-height: 1.5; }
        .sec-About_01 .content-box .image-area {
          margin-top: 5.33333vw; }
          .sec-About_01 .content-box .image-area figure {
            display: block;
            width: 34.66667vw; }
          .sec-About_01 .content-box .image-area figure:nth-child(n+3) {
            margin-top: 1.33333vw; } }

.sec-About_02 {
  margin-top: 14.64129vw;
  position: relative; }
  .sec-About_02::before {
    content: '';
    width: 35.2123vw;
    height: 35.57833vw;
    position: absolute;
    top: -20.64422vw;
    right: -3.87994vw;
    background: url("../../assets/img/about/bg_triangle_04.png") center center/cover no-repeat;
    z-index: -1; }
  .sec-About_02::after {
    content: '';
    width: 45.09517vw;
    height: 45.60761vw;
    position: absolute;
    bottom: -30.30747vw;
    left: -9.80966vw;
    background: url("../../assets/img/about/bg_triangle_02.png") center center/cover no-repeat;
    z-index: -1; }
  .sec-About_02 .content-box {
    height: auto;
    padding: 6.00293vw 10.2489vw 10.2489vw;
    background: #fff; }
    .sec-About_02 .content-box .lead {
      font-size: 1.68375vw;
      line-height: 6.14934vw;
      margin: 4.75842vw auto 0;
      text-align: center; }
      .sec-About_02 .content-box .lead span {
        display: block; }
        .sec-About_02 .content-box .lead span + span {
          border-top: 1px solid #b3b3b3; }
    .sec-About_02 .content-box .image-area {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 5.49048vw; }
      .sec-About_02 .content-box .image-area figure {
        display: block;
        width: 12.26208vw; }
      @media screen and (min-width: 500px) {
        .sec-About_02 .content-box .image-area figure:nth-child(n+5) {
          margin-top: 0.73206vw; } }
    @media screen and (max-width: 499px) {
      .sec-About_02 .content-box {
        padding: 10.66667vw 5.33333vw 9.33333vw; }
        .sec-About_02 .content-box .lead {
          font-size: 3.2vw;
          line-height: 9.33333vw;
          margin: 4vw auto 0; }
        .sec-About_02 .content-box .image-area {
          margin-top: 4vw; }
          .sec-About_02 .content-box .image-area figure {
            display: block;
            width: 17.86667vw;
            margin-left: 1.33333vw; }
          .sec-About_02 .content-box .image-area figure:nth-child(4n+1) {
            margin-left: 0; }
          .sec-About_02 .content-box .image-area figure:nth-child(n+5) {
            margin-top: 1.33333vw; } }

.sec-About_03 {
  margin-top: 6.58858vw;
  padding-top: 5.12445vw;
  position: relative; }
  .sec-About_03::before {
    content: '';
    width: 15.81259vw;
    height: 15.959vw;
    position: absolute;
    top: 50.51245vw;
    right: 0;
    background: url("../../assets/img/about/bg_triangle_06.png") center center/cover no-repeat;
    z-index: -1; }
  @media screen and (max-width: 499px) {
    .sec-About_03 {
      margin-top: 0vw;
      padding-top: 18.66667vw; } }
  .sec-About_03 .content-inr {
    margin-top: 5.12445vw;
    display: inline-block; }
  .sec-About_03 .text {
    margin-top: -0.51245vw;
    width: 43.48463vw;
    font-size: 1.0981vw;
    line-height: 2;
    float: left; }
    .sec-About_03 .text span {
      display: inline-block; }
    .sec-About_03 .text .mt-spacer {
      padding-top: 2em; }
    @media screen and (max-width: 499px) {
      .sec-About_03 .text {
        margin-top: 2.66667vw;
        width: 100%;
        float: none;
        font-size: 3.46667vw;
        text-align: justify; }
        .sec-About_03 .text span {
          display: inline; } }
  .sec-About_03 .image-area {
    width: 25.62225vw;
    float: right; }
    .sec-About_03 .image-area .sch-name {
      font-size: 1.0981vw;
      letter-spacing: 0.05em;
      padding-left: 0.05em;
      text-align: center;
      margin-top: 2.56223vw; }
    .sec-About_03 .image-area .riji-name {
      font-size: 1.0981vw;
      margin-top: 1.1713vw;
      letter-spacing: 0.15em;
      padding-left: 0.15em;
      text-align: center; }
      .sec-About_03 .image-area .riji-name span {
        font-size: 1.75695vw;
        padding-left: 0.5em; }
    @media screen and (max-width: 499px) {
      .sec-About_03 .image-area {
        width: 53.33333vw;
        margin: 10.66667vw auto 0;
        float: none; }
        .sec-About_03 .image-area .sch-name {
          font-size: 3.2vw;
          letter-spacing: 0.05em;
          padding-left: 0.05em;
          margin-top: 4vw; }
        .sec-About_03 .image-area .riji-name {
          font-size: 3.2vw;
          margin-top: 2.66667vw;
          letter-spacing: 0.05em;
          padding-left: 0.05em; }
          .sec-About_03 .image-area .riji-name span {
            font-size: 4.26667vw;
            padding-left: 0.5em; } }

.sec-Access {
  margin-top: 7.32064vw; }
  @media screen and (max-width: 499px) {
    .sec-Access {
      margin-top: 13.33333vw; } }
  .sec-Access .map {
    margin-top: 2.19619vw; }
    @media screen and (max-width: 499px) {
      .sec-Access .map {
        margin-top: 5.33333vw; } }
    .sec-Access .map .address {
      font-size: 1.02489vw;
      margin-top: 1.31772vw; }
      @media screen and (max-width: 499px) {
        .sec-Access .map .address {
          font-size: 3.2vw; } }
  .sec-Access .school-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 0.73206vw;
    margin-left: -4.75842vw; }
    @media screen and (max-width: 499px) {
      .sec-Access .school-list {
        margin-top: 2.66667vw; } }
    .sec-Access .school-list li {
      width: 50%;
      padding-left: 4.75842vw;
      margin-top: 3.29429vw; }
      @media screen and (max-width: 499px) {
        .sec-Access .school-list li {
          width: 100%;
          margin-top: 5.33333vw; } }
    .sec-Access .school-list h4 {
      font-size: 1.46413vw;
      font-weight: bold;
      letter-spacing: 0.1em; }
      .sec-Access .school-list h4 a {
        padding-right: 2.04978vw;
        display: inline-block;
        background: url("../../assets/img/common/icon_target_orange.svg") right bottom/1.50073vw 1.35432vw no-repeat; }
      @media screen and (max-width: 499px) {
        .sec-Access .school-list h4 {
          font-size: 4.26667vw; }
          .sec-Access .school-list h4 a {
            padding-right: 5.6vw;
            background-position: right center;
            background-size: 4.13333vw 3.73333vw; } }
    .sec-Access .school-list .course {
      font-size: 1.02489vw;
      color: #f7931e;
      letter-spacing: 0.1em;
      margin-top: 1.68375vw; }
      @media screen and (max-width: 499px) {
        .sec-Access .school-list .course {
          font-size: 3.2vw;
          margin-top: 4vw; } }
    .sec-Access .school-list p {
      font-size: 1.1713vw;
      line-height: 1.6;
      letter-spacing: 0.1em;
      margin-top: 0.36603vw; }
      @media screen and (max-width: 499px) {
        .sec-Access .school-list p {
          margin-top: 1.33333vw;
          font-size: 3.73333vw;
          line-height: 1.6 !important; } }
    .sec-Access .school-list .close h4 a {
      color: #aaa;
      pointer-events: none;
      background: none; }

.sec-Access.next {
  border-top: 1px solid #c8c8c8;
  padding-top: 5.12445vw; }
  @media screen and (max-width: 499px) {
    .sec-Access.next {
      padding-top: 13.33333vw; } }

.sec-Company {
  margin-top: 7.32064vw; }
  @media screen and (max-width: 499px) {
    .sec-Company {
      margin-top: 18.66667vw; } }
  .sec-Company .content-title-under {
    font-size: 1.0981vw;
    text-align: center;
    margin: 1.0981vw auto 0; }
    @media screen and (max-width: 499px) {
      .sec-Company .content-title-under {
        font-size: 3.46667vw !important;
        margin: 4vw auto 0;
        text-align: justify; } }
  .sec-Company .company-content {
    margin-top: 5.12445vw; }
    @media screen and (max-width: 499px) {
      .sec-Company .company-content {
        margin-top: 13.33333vw; } }
    .sec-Company .company-content .text-title-secandly {
      text-align: center; }
    .sec-Company .company-content p {
      margin-top: 1.0981vw;
      text-align: center; }
      @media screen and (max-width: 499px) {
        .sec-Company .company-content p {
          margin-top: 2.66667vw;
          text-align: justify; } }
  .sec-Company .career-map {
    border-top: 1px solid #c8c8c8;
    margin-top: 1.83016vw;
    padding-top: 1.46413vw;
    padding-bottom: 1.0981vw; }
    @media screen and (max-width: 499px) {
      .sec-Company .career-map {
        margin-top: 6.66667vw;
        padding-top: 5.33333vw;
        padding-bottom: 4vw; } }
    .sec-Company .career-map .banner {
      width: 15.81259vw;
      margin: 1.46413vw auto 0; }
      .sec-Company .career-map .banner img {
        width: 100%; }
      @media screen and (max-width: 499px) {
        .sec-Company .career-map .banner {
          width: 57.6vw;
          margin: 5.33333vw auto 0; } }
    .sec-Company .career-map .download-list {
      margin-top: 0vw; }
    .sec-Company .career-map .text {
      text-align: center; }
    .sec-Company .career-map .career-map-box {
      margin: 2.92826vw auto 0;
      width: 48.75549vw;
      border: 1px solid #f7931e;
      padding: 2.19619vw 0 0 0; }
      .sec-Company .career-map .career-map-box .tit {
        font-size: 1.1713vw;
        font-weight: bold;
        letter-spacing: 0.05em;
        text-align: center; }
      .sec-Company .career-map .career-map-box .inr {
        margin-top: 1.46413vw;
        padding: 0 2.19619vw 0; }
      .sec-Company .career-map .career-map-box .process-list {
        display: flex;
        margin-top: 0.36603vw;
        margin-bottom: 0.73206vw; }
        .sec-Company .career-map .career-map-box .process-list li {
          font-size: 1.02489vw; }
          .sec-Company .career-map .career-map-box .process-list li span {
            border: 1px solid #CCC;
            padding: 0.73206vw;
            width: 13.17716vw;
            text-align: center;
            display: block; }
          .sec-Company .career-map .career-map-box .process-list li + li {
            padding-left: 1.97657vw;
            margin-left: 0.36603vw;
            background: url("../../assets/img/common/process_arrow.svg") left center/1.61054vw 0.73206vw no-repeat; }
      .sec-Company .career-map .career-map-box .p-list-asterisk {
        font-size: 0.87848vw;
        margin-top: 1.46413vw;
        line-height: 2; }
      .sec-Company .career-map .career-map-box .contact {
        background: #eee;
        padding: 1.83016vw 2.19619vw 1.24451vw;
        margin-top: 1.83016vw; }
        .sec-Company .career-map .career-map-box .contact p {
          text-align: center;
          margin-top: 0.58565vw; }
      @media screen and (max-width: 499px) {
        .sec-Company .career-map .career-map-box {
          margin: 8vw auto 0;
          width: 100%;
          padding: 8vw 0 0 0; }
          .sec-Company .career-map .career-map-box .tit {
            font-size: 3.73333vw; }
          .sec-Company .career-map .career-map-box .inr {
            margin-top: 2.66667vw;
            padding: 0 5.33333vw 0; }
          .sec-Company .career-map .career-map-box .num {
            text-align: center;
            font-size: 3.73333vw !important;
            margin-top: 4vw; }
          .sec-Company .career-map .career-map-box .process-list {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin-top: 2.13333vw;
            margin-bottom: 2.66667vw; }
            .sec-Company .career-map .career-map-box .process-list li {
              font-size: 3.2vw; }
              .sec-Company .career-map .career-map-box .process-list li span {
                padding: 2.66667vw;
                width: 48vw; }
              .sec-Company .career-map .career-map-box .process-list li + li {
                padding-top: 4.8vw;
                margin-top: 2.13333vw;
                padding-left: 0;
                margin-left: 0;
                background: url("../../assets/img/common/process_arrow_sp.svg") center top/2.66667vw 3.2vw no-repeat; }
          .sec-Company .career-map .career-map-box .p-list-asterisk {
            font-size: 3.2vw;
            margin-top: 5.33333vw;
            line-height: 2; }
          .sec-Company .career-map .career-map-box .contact {
            background: #eee;
            padding: 4.8vw 5.33333vw 4vw;
            margin-top: 5.33333vw; }
            .sec-Company .career-map .career-map-box .contact .tit {
              line-height: 1.5; }
            .sec-Company .career-map .career-map-box .contact p {
              text-align: center;
              margin-top: 1.33333vw;
              line-height: 1.7 !important; } }

.download-list {
  width: 45.38799vw;
  margin: 2.19619vw auto 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap; }
  @media screen and (max-width: 499px) {
    .download-list {
      width: 100%;
      margin: 5.33333vw auto 0; } }
  .download-list li {
    margin-top: 1.46413vw; }
    .download-list li a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 21.96193vw;
      height: 4.39239vw;
      background: linear-gradient(to right, #ffbe55, #f7931e);
      color: #fff;
      font-size: 1.02489vw;
      line-height: 1.5714;
      letter-spacing: 0.1em;
      padding-left: 0.1em;
      text-align: center; }
    @media screen and (min-width: 500px) {
      .download-list li:nth-child(even) {
        margin-left: 1.46413vw; } }
    @media screen and (max-width: 499px) {
      .download-list li {
        margin-top: 2.66667vw; }
        .download-list li a {
          width: 66.66667vw;
          height: 13.33333vw;
          font-size: 3.2vw;
          line-height: 1.4; } }

.sec-License {
  margin-top: 10.2489vw; }
  @media screen and (max-width: 499px) {
    .sec-License {
      margin-top: 26.66667vw; } }
  .sec-License .p-list-asterisk {
    margin-top: 1.46413vw;
    font-size: 12px;
    text-align: center;
    line-height: 1.7; }
    @media screen and (max-width: 499px) {
      .sec-License .p-list-asterisk {
        margin-top: 5.33333vw;
        text-align: left; } }
  .sec-License .license-lead .text-title-secandly {
    text-align: center;
    margin-top: 2.19619vw; }
    @media screen and (max-width: 499px) {
      .sec-License .license-lead .text-title-secandly {
        margin-top: 5.33333vw; } }
  .sec-License .license-lead p {
    margin-top: 1.0981vw;
    text-align: center; }
    @media screen and (max-width: 499px) {
      .sec-License .license-lead p {
        margin-top: 2.66667vw;
        text-align: justify; } }
  .sec-License .license-content {
    margin-top: 3.66032vw; }
    @media screen and (max-width: 499px) {
      .sec-License .license-content {
        margin-top: 13.33333vw; } }
    .sec-License .license-content .mt-spacer {
      margin-top: 3.29429vw; }
      @media screen and (max-width: 499px) {
        .sec-License .license-content .mt-spacer {
          margin-top: 8vw; } }
    .sec-License .license-content p {
      margin-top: 1.0981vw; }
      @media screen and (max-width: 499px) {
        .sec-License .license-content p {
          margin-top: 2.66667vw; } }
    @media screen and (max-width: 499px) {
      .sec-License .license-content .unbar-title-secandly span {
        display: block;
        font-size: 3.73333vw; } }
    .sec-License .license-content .text-title-secandly {
      margin-top: 2.92826vw; }
      @media screen and (max-width: 499px) {
        .sec-License .license-content .text-title-secandly {
          margin-top: 8vw; } }
    .sec-License .license-content .lisence-list {
      font-size: 1.02489vw;
      line-height: 2;
      letter-spacing: 0.1em;
      padding-left: 1em;
      margin-top: 0.87848vw; }
      @media screen and (min-width: 1366px) {
        .sec-License .license-content .lisence-list {
          font-size: 14px;
          margin-top: 12px; } }
      @media screen and (max-width: 499px) {
        .sec-License .license-content .lisence-list {
          font-size: 3.2vw;
          letter-spacing: 0.05em;
          padding-left: 0;
          margin-top: 2.66667vw; } }
      .sec-License .license-content .lisence-list .course {
        font-weight: bold;
        position: relative;
        padding-left: 0.1em; }
        .sec-License .license-content .lisence-list .course:before {
          content: '';
          position: absolute;
          left: -0.75em;
          top: 0;
          bottom: 0;
          margin: auto 0;
          width: 5px;
          height: 1px;
          background: #000; }
        @media screen and (max-width: 499px) {
          .sec-License .license-content .lisence-list .course {
            font-size: 3.73333vw;
            padding-left: 1em; }
            .sec-License .license-content .lisence-list .course:before {
              left: 0.2em; } }
  .sec-License .license-content + .license-content {
    margin-top: 4.75842vw;
    padding-top: 4.75842vw;
    border-top: 1px solid #c8c8c8; }
    @media screen and (max-width: 499px) {
      .sec-License .license-content + .license-content {
        margin-top: 13.33333vw;
        padding-top: 13.33333vw; } }

.sec-Graduate {
  margin-top: 7.32064vw; }
  @media screen and (max-width: 499px) {
    .sec-Graduate {
      margin-top: 18.66667vw; } }
  .sec-Graduate .graduate-school-list {
    margin-top: 3.66032vw; }
    @media screen and (max-width: 499px) {
      .sec-Graduate .graduate-school-list {
        margin-top: 8vw; } }
  .sec-Graduate .text-title-secandly.center {
    text-align: center;
    margin-top: 5.12445vw; }
    @media screen and (max-width: 499px) {
      .sec-Graduate .text-title-secandly.center {
        margin-top: 12vw; } }
  .sec-Graduate .text-title-secandly.second {
    margin-top: 4.39239vw; }
    @media screen and (max-width: 499px) {
      .sec-Graduate .text-title-secandly.second {
        margin-top: 10.66667vw; } }
  .sec-Graduate .graduate-content {
    margin-top: 5.12445vw; }
    @media screen and (max-width: 499px) {
      .sec-Graduate .graduate-content {
        margin-top: 10.66667vw; } }
  .sec-Graduate .mt-spacer {
    margin-top: 0.73206vw; }
  .sec-Graduate .lbar-title {
    margin-top: 2.92826vw; }
    @media screen and (max-width: 499px) {
      .sec-Graduate .lbar-title {
        margin-top: 5.33333vw; } }
  .sec-Graduate ul {
    font-size: 1.02489vw;
    line-height: 2;
    letter-spacing: 0.05em; }
    @media screen and (max-width: 499px) {
      .sec-Graduate ul {
        font-size: 3.2vw; } }
  .sec-Graduate .p-list-square.second {
    margin-top: 1em; }

.sec-Access + .sec-Access {
  border-top: 1px solid #c8c8c8;
  padding-top: 70px; }

.js_Hover {
  transition: 0.7s; }

.js_Hover:hover, .js_Hover:focus {
  transform: scale(1.05); }

.sec-Method_top_01 {
  margin-top: 10.2489vw; }
  .sec-Method_top_01 .title {
    font-size: 1.75695vw;
    letter-spacing: 0.2em;
    padding-left: 0.2em;
    text-align: center; }
  .sec-Method_top_01 .method-list {
    margin-top: 2.04978vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
    .sec-Method_top_01 .method-list li {
      width: 24.15813vw;
      margin-left: 0.36603vw; }
      .sec-Method_top_01 .method-list li h4 {
        font-size: 1.46413vw;
        text-align: center;
        margin-top: 2.04978vw;
        letter-spacing: 0.2em;
        padding-left: 0.2em; }
      .sec-Method_top_01 .method-list li .text {
        font-size: 0.95168vw;
        line-height: 1.9231;
        margin-top: 1.31772vw;
        text-align: center;
        letter-spacing: 0.1em;
        padding: 0 0.73206vw; }
    @media screen and (min-width: 500px) {
      .sec-Method_top_01 .method-list li:nth-child(3n+1) {
        margin-left: 0; }
      .sec-Method_top_01 .method-list li:nth-child(n+4) {
        margin-top: 5.12445vw; } }
  @media screen and (max-width: 499px) {
    .sec-Method_top_01 {
      margin-top: 18.66667vw; }
      .sec-Method_top_01 .title {
        font-size: 4.8vw;
        line-height: 1.7;
        letter-spacing: 0.1em;
        padding-left: 0.1em; }
      .sec-Method_top_01 .method-list {
        margin-top: 8vw; }
        .sec-Method_top_01 .method-list li {
          width: 100%; }
          .sec-Method_top_01 .method-list li + li {
            margin-top: 13.33333vw; }
          .sec-Method_top_01 .method-list li h4 {
            font-size: 4.26667vw;
            margin-top: 5.33333vw; }
          .sec-Method_top_01 .method-list li .text {
            font-size: 3.46667vw;
            margin-top: 2.66667vw;
            padding: 0 2.66667vw; } }

.sec-Method_top_02 {
  margin-top: 5.12445vw;
  padding-top: 5.12445vw; }
  @media screen and (max-width: 499px) {
    .sec-Method_top_02 {
      margin-top: 8vw;
      padding-top: 18.66667vw; } }
  .sec-Method_top_02 .title {
    font-size: 1.75695vw;
    letter-spacing: 0.2em;
    padding-left: 0.2em;
    text-align: center; }
    @media screen and (max-width: 499px) {
      .sec-Method_top_02 .title {
        font-size: 4.8vw;
        line-height: 1.7;
        letter-spacing: 0.1em;
        padding-left: 0.1em; } }
  .sec-Method_top_02 .school-list {
    margin-top: 2.04978vw;
    display: flex;
    flex-wrap: wrap;
    margin-left: -1.46413vw; }
    .sec-Method_top_02 .school-list > li {
      width: 17.20351vw;
      margin-left: 1.46413vw; }
      @media screen and (min-width: 500px) {
        .sec-Method_top_02 .school-list > li:nth-child(n+5) {
          padding-top: 5.12445vw; } }
      .sec-Method_top_02 .school-list > li h4 {
        font-size: 1.0981vw;
        font-weight: bold;
        line-height: 1.4667;
        margin-top: 1.1713vw;
        letter-spacing: 0.05em; }
      .sec-Method_top_02 .school-list > li .p-list-square.gray {
        font-size: 0.95168vw;
        letter-spacing: 0.05em;
        line-height: 1.5385;
        margin-top: 0.73206vw;
        min-height: 2.92826vw; }
        .sec-Method_top_02 .school-list > li .p-list-square.gray li {
          padding-left: 1.2em; }
        .sec-Method_top_02 .school-list > li .p-list-square.gray li:before {
          color: #999;
          margin-left: -1.2em; }
    @media screen and (max-width: 499px) {
      .sec-Method_top_02 .school-list {
        margin-top: 8vw;
        margin-left: 0; }
        .sec-Method_top_02 .school-list > li {
          width: 43.33333vw;
          margin-left: 0;
          position: relative;
          min-height: 70.66667vw; }
          .sec-Method_top_02 .school-list > li:nth-child(even) {
            margin-left: 2.66667vw; }
          .sec-Method_top_02 .school-list > li h4 {
            font-size: 3.46667vw;
            margin-top: 3.2vw; }
          .sec-Method_top_02 .school-list > li .p-list-square.gray {
            font-size: 2.66667vw;
            margin-top: 1.33333vw;
            min-height: 8vw;
            letter-spacing: 0.01em; }
            .sec-Method_top_02 .school-list > li .p-list-square.gray li {
              padding-left: 1.0em; }
            .sec-Method_top_02 .school-list > li .p-list-square.gray li:before {
              margin-left: -1.0em; }
        .sec-Method_top_02 .school-list > li:nth-child(n+3) {
          margin-top: 6.66667vw; }
        .sec-Method_top_02 .school-list > li:nth-child(n+6) {
          min-height: 76vw; } }
  .sec-Method_top_02 .mt-spacer {
    margin-top: 3.00146vw; }

.p-target-linkbox.Method a {
  transition: 0.2s; }
  .p-target-linkbox.Method a svg {
    transition: 0.2s; }

.p-target-linkbox.Method a:hover {
  background-color: #fff; }

.p-target-linkbox.Method a.bgc-Pet-kanazawa:hover, .p-target-linkbox.Method a.bgc-Pet-fukui:hover {
  color: #29abe2; }
  .p-target-linkbox.Method a.bgc-Pet-kanazawa:hover svg, .p-target-linkbox.Method a.bgc-Pet-fukui:hover svg {
    fill: #29abe2; }

.p-target-linkbox.Method a.bgc-Pet-kango:hover {
  color: #ff85b9; }
  .p-target-linkbox.Method a.bgc-Pet-kango:hover svg {
    fill: #ff85b9; }

.p-target-linkbox.Method a.bgc-Bisen:hover {
  color: #c1272d; }
  .p-target-linkbox.Method a.bgc-Bisen:hover svg {
    fill: #c1272d; }

.p-target-linkbox.Method a.bgc-Pet-kanazawa:hover, .p-target-linkbox.Method a.bgc-Pet-fukui:hover {
  color: #29abe2; }
  .p-target-linkbox.Method a.bgc-Pet-kanazawa:hover svg, .p-target-linkbox.Method a.bgc-Pet-fukui:hover svg {
    fill: #29abe2; }

.p-target-linkbox.Method a.bgc-Medical:hover {
  color: #fbb03b; }
  .p-target-linkbox.Method a.bgc-Medical:hover svg {
    fill: #fbb03b; }

.p-target-linkbox.Method a.bgc-HoteBra:hover {
  color: #1564a0; }
  .p-target-linkbox.Method a.bgc-HoteBra:hover svg {
    fill: #1564a0; }

.p-target-linkbox.Method a.bgc-Sweets:hover {
  color: #754c24; }
  .p-target-linkbox.Method a.bgc-Sweets:hover svg {
    fill: #754c24; }

.p-target-linkbox.Method a.bgc-Cooking:hover {
  color: #8e8887; }
  .p-target-linkbox.Method a.bgc-Cooking:hover svg {
    fill: #8e8887; }

.p-target-linkbox.Method a.bgc-Cycle:hover {
  color: #009245; }
  .p-target-linkbox.Method a.bgc-Cycle:hover svg {
    fill: #009245; }

.p-target-linkbox.Method a.bgc-Food:hover {
  color: #000080; }
  .p-target-linkbox.Method a.bgc-Food:hover svg {
    fill: #000080; }

@media screen and (max-width: 499px) {
  .p-target-linkbox.Method {
    margin-top: 2.66667vw;
    position: absolute;
    bottom: 0;
    right: 0; }
    .p-target-linkbox.Method a {
      font-size: 3.2vw;
      height: 9.33333vw; }
      .p-target-linkbox.Method a svg {
        margin-left: 1.33333vw; } }

.Method-title {
  text-align: center;
  margin-top: 4.39239vw; }
  .Method-title .c-text-Time {
    font-size: 10.2489vw;
    color: #f7931e; }
  .Method-title h3 {
    font-size: 2.56223vw;
    letter-spacing: 0.15em;
    line-height: 1.4286; }
  .Method-title div {
    font-size: 1.46413vw;
    letter-spacing: 0.15em;
    margin-top: 1.1713vw; }
  .Method-title p {
    margin-top: 1.31772vw; }
  @media screen and (max-width: 499px) {
    .Method-title {
      margin-top: 16vw; }
      .Method-title .c-text-Time {
        font-size: 21.33333vw; }
      .Method-title h3 {
        font-size: 6.66667vw;
        margin-top: 0.8vw; }
      .Method-title div {
        letter-spacing: 0.05em;
        font-size: 3.73333vw;
        line-height: 1.7;
        margin-top: 2.66667vw; } }

.sec-Method_01 .text_ac-co {
  margin-top: 7.32064vw;
  margin: 0 auto;
  text-align: center; }
  .sec-Method_01 .text_ac-co img {
    width: 23.42606vw; }
  .sec-Method_01 .text_ac-co p {
    width: 58.56515vw;
    margin: 5.49048vw auto 0; }
    .sec-Method_01 .text_ac-co p span {
      display: inline-block; }
  @media screen and (max-width: 499px) {
    .sec-Method_01 .text_ac-co {
      margin-top: 18.66667vw; }
      .sec-Method_01 .text_ac-co img {
        width: 53.33333vw; }
      .sec-Method_01 .text_ac-co p {
        width: 100%;
        margin: 10.66667vw auto 0;
        text-align: justify; }
        .sec-Method_01 .text_ac-co p span {
          display: inline; } }

.sec-Method_01 .company-content {
  margin-top: 9.88287vw; }
  .sec-Method_01 .company-content .rogo-list {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1.68375vw; }
    @media screen and (min-width: 500px) {
      .sec-Method_01 .company-content .rogo-list li + li {
        margin-left: 2.19619vw; } }
  @media screen and (max-width: 499px) {
    .sec-Method_01 .company-content {
      margin-top: 18.66667vw; }
      .sec-Method_01 .company-content .rogo-list {
        flex-wrap: wrap;
        margin-top: 5.33333vw; }
        .sec-Method_01 .company-content .rogo-list li.aeon img {
          height: 35px; }
        .sec-Method_01 .company-content .rogo-list li.ikk img {
          height: 44px;
          margin-left: 5.33333vw; }
        .sec-Method_01 .company-content .rogo-list li.hotel img {
          height: 28px;
          margin-top: 4vw; }
        .sec-Method_01 .company-content .rogo-list li.aozora img {
          height: 35px;
          margin-top: 4vw; } }

.sec-Method_01 .voice-content {
  margin-top: 9.88287vw; }
  .sec-Method_01 .voice-content .bracket {
    font-size: 2.04978vw;
    color: #f7931e;
    margin-top: 2.92826vw;
    text-align: center;
    letter-spacing: 0.1em; }
  .sec-Method_01 .voice-content .lead {
    font-size: 1.46413vw;
    line-height: 1.7;
    margin-top: 2.19619vw;
    text-align: center;
    letter-spacing: 0.1em; }
    .sec-Method_01 .voice-content .lead span {
      display: inline-block; }
  @media screen and (max-width: 499px) {
    .sec-Method_01 .voice-content {
      margin-top: 26.66667vw; }
      .sec-Method_01 .voice-content .bracket {
        font-size: 5.86667vw;
        margin-top: 4vw;
        line-height: 1.5; }
      .sec-Method_01 .voice-content .lead {
        font-size: 4vw;
        line-height: 2;
        margin-top: 4vw; }
        .sec-Method_01 .voice-content .lead span {
          display: inline; } }
  .sec-Method_01 .voice-content .voice-example {
    width: 55.6369vw;
    display: flex;
    justify-content: space-between;
    margin: 3.29429vw auto 0; }
    .sec-Method_01 .voice-content .voice-example .image-area {
      width: 30.74671vw; }
    .sec-Method_01 .voice-content .voice-example .text-area {
      width: 22.694vw;
      letter-spacing: 0.1em; }
      .sec-Method_01 .voice-content .voice-example .text-area .role {
        font-size: 1.02489vw; }
      .sec-Method_01 .voice-content .voice-example .text-area .name {
        font-size: 1.83016vw;
        margin-top: 1.1713vw; }
        .sec-Method_01 .voice-content .voice-example .text-area .name span {
          font-size: 1.0981vw; }
      .sec-Method_01 .voice-content .voice-example .text-area .work {
        font-size: 1.0981vw;
        margin-top: 1.1713vw;
        line-height: 1.4; }
      .sec-Method_01 .voice-content .voice-example .text-area .text {
        font-size: 1.0981vw;
        line-height: 2;
        text-align: justify;
        margin-top: 1.31772vw; }
    @media screen and (max-width: 499px) {
      .sec-Method_01 .voice-content .voice-example {
        flex-wrap: wrap;
        width: 100%;
        margin: 8vw auto 0; }
        .sec-Method_01 .voice-content .voice-example .image-area {
          width: 100%; }
        .sec-Method_01 .voice-content .voice-example .text-area {
          width: 100%;
          margin-top: 4.8vw; }
          .sec-Method_01 .voice-content .voice-example .text-area .role {
            font-size: 3.2vw; }
          .sec-Method_01 .voice-content .voice-example .text-area .name {
            font-size: 5.33333vw;
            margin-top: 2.66667vw; }
            .sec-Method_01 .voice-content .voice-example .text-area .name span {
              font-size: 3.2vw; }
          .sec-Method_01 .voice-content .voice-example .text-area .work {
            font-size: 3.2vw;
            margin-top: 2.66667vw;
            line-height: 1.4; }
          .sec-Method_01 .voice-content .voice-example .text-area .text {
            font-size: 3.2vw;
            line-height: 2;
            text-align: justify;
            margin-top: 4vw; } }

.sec-Method_02 .manabi-content {
  width: 92.67936vw;
  height: 46.92533vw;
  margin: 0 auto;
  padding-top: 7.32064vw;
  background: url("../../assets/img/method/bg_image_manabi.jpg") center center/100% 100% no-repeat; }
  @media screen and (max-width: 499px) {
    .sec-Method_02 .manabi-content {
      width: 100%;
      height: 50.66667vw;
      margin-top: 13.33333vw;
      padding-top: 0;
      margin-bottom: 2.66667vw; } }
  .sec-Method_02 .manabi-content .lead {
    font-size: 2.92826vw;
    line-height: 1.5;
    letter-spacing: 0.15em;
    padding-left: 0.15em;
    text-align: center; }
    .sec-Method_02 .manabi-content .lead span {
      color: #c1272d; }
    @media screen and (max-width: 499px) {
      .sec-Method_02 .manabi-content .lead {
        font-size: 5.86667vw; } }

.sec-Method_02 .manabi-text {
  text-align: center;
  width: 58.56515vw;
  margin: -0.36603vw auto 0; }
  .sec-Method_02 .manabi-text span {
    display: inline-block; }
  @media screen and (max-width: 499px) {
    .sec-Method_02 .manabi-text {
      text-align: justify;
      margin: -1.33333vw auto 0;
      width: 100%; }
      .sec-Method_02 .manabi-text span {
        display: inline; } }

.sec-Method_02 .voice-content {
  margin-top: 10.2489vw; }
  .sec-Method_02 .voice-content .image {
    display: block;
    margin-top: 1.83016vw;
    width: 76.1347vw;
    margin-left: -1.46413vw; }
  @media screen and (max-width: 499px) {
    .sec-Method_02 .voice-content {
      margin-top: 18.66667vw; }
      .sec-Method_02 .voice-content .image {
        margin-top: 5.33333vw;
        width: 100%;
        margin-left: 0; } }

.sec-Method_03 .practice-content {
  width: 92.67936vw;
  height: 37.5549vw;
  margin: 5.12445vw auto 0;
  background: url("../../assets/img/method/bg_image_practice_pc.jpg") center center/100% 100% no-repeat;
  display: flex;
  align-items: center;
  justify-content: center; }
  @media screen and (max-width: 499px) {
    .sec-Method_03 .practice-content {
      width: 100%;
      height: 70.13333vw;
      margin: 13.33333vw auto 0;
      background-image: url("../../assets/img/method/bg_image_practice_sp.jpg"); } }
  .sec-Method_03 .practice-content .lead {
    font-size: 2.48902vw;
    line-height: 1.5;
    letter-spacing: 0.1em;
    padding-right: 1.83016vw;
    margin-top: -1.83016vw;
    text-align: center; }
    .sec-Method_03 .practice-content .lead span {
      color: #c1272d; }
    @media screen and (max-width: 499px) {
      .sec-Method_03 .practice-content .lead {
        font-size: 5.33333vw; } }

.sec-Method_03 .practice-text {
  text-align: center;
  width: 58.56515vw;
  margin: 1.46413vw auto 0; }
  .sec-Method_03 .practice-text span {
    display: inline-block; }
  @media screen and (max-width: 499px) {
    .sec-Method_03 .practice-text {
      text-align: justify;
      margin: 4vw auto 0;
      width: 89.33333vw; }
      .sec-Method_03 .practice-text span {
        display: inline; } }

.sec-Method_03 .shop-content {
  max-width: 78.03807vw;
  margin: 5.85652vw auto 0; }
  @media screen and (max-width: 499px) {
    .sec-Method_03 .shop-content {
      max-width: 89.33333vw;
      margin: 16vw auto 0; } }
  .sec-Method_03 .shop-content .container {
    margin-top: 3.66032vw; }
    @media screen and (max-width: 499px) {
      .sec-Method_03 .shop-content .container {
        margin-top: 8vw; } }
  .sec-Method_03 .shop-content .container + .container {
    margin-top: 4.39239vw; }
    @media screen and (max-width: 499px) {
      .sec-Method_03 .shop-content .container + .container {
        margin-top: 10.66667vw; } }
  .sec-Method_03 .shop-content .text-orange-title {
    font-size: 2.34261vw;
    font-weight: bold;
    color: #f7931e;
    text-align: center;
    letter-spacing: 0.15em;
    padding-left: 0.15em; }
    @media screen and (max-width: 499px) {
      .sec-Method_03 .shop-content .text-orange-title {
        font-size: 5.33333vw; } }
  .sec-Method_03 .shop-content .flag {
    width: 9.51684vw;
    margin: 0 auto;
    background: #f7931e;
    color: #fff;
    text-align: center;
    font-size: 1.1713vw;
    letter-spacing: 0.1em;
    padding: 0.51245vw; }
    @media screen and (max-width: 499px) {
      .sec-Method_03 .shop-content .flag {
        width: 26.66667vw;
        font-size: 3.2vw;
        padding: 1.86667vw; } }
  .sec-Method_03 .shop-content .shop-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 1.46413vw; }
    .sec-Method_03 .shop-content .shop-list li img {
      width: 100%; }
    .sec-Method_03 .shop-content .shop-list li p {
      text-align: center;
      margin-top: 0.95168vw; }
    .sec-Method_03 .shop-content .shop-list li + li {
      margin-left: 1.68375vw; }
    .sec-Method_03 .shop-content .shop-list.first li {
      width: 24.89019vw; }
    .sec-Method_03 .shop-content .shop-list.second li {
      width: 41.50805vw; }
    @media screen and (max-width: 499px) {
      .sec-Method_03 .shop-content .shop-list {
        margin-top: 5.33333vw; }
        .sec-Method_03 .shop-content .shop-list li img {
          width: 100%; }
        .sec-Method_03 .shop-content .shop-list li p {
          text-align: center;
          margin-top: 1.86667vw; }
        .sec-Method_03 .shop-content .shop-list.first li {
          width: 90%; }
          .sec-Method_03 .shop-content .shop-list.first li + li {
            margin-top: 5.33333vw; }
        .sec-Method_03 .shop-content .shop-list.second li {
          width: 90%; } }

.sec-Method_03 .image-middle {
  margin: 4.75842vw auto 7.68668vw; }
  @media screen and (max-width: 499px) {
    .sec-Method_03 .image-middle {
      margin: 10.66667vw auto 16vw; } }

.Method-bnr {
  width: 36.60322vw;
  margin: 10.2489vw auto 0; }
  @media screen and (max-width: 499px) {
    .Method-bnr {
      width: 80vw;
      margin: 26.66667vw auto 0; } }

.Method-pager {
  width: 29.28258vw;
  margin: 1.83016vw auto 0;
  display: flex;
  justify-content: space-between; }
  .Method-pager .arrow {
    width: 3.22108vw;
    height: 5.27086vw;
    margin-top: 1.83016vw; }
  .Method-pager .center {
    width: 20.4978vw;
    text-align: center;
    margin: 0 auto;
    font-size: 1.31772vw;
    line-height: 1.3889; }
    .Method-pager .center .num {
      font-size: 1.90337vw; }
    .Method-pager .center.last {
      font-size: 1.90337vw;
      display: flex;
      justify-content: center;
      align-items: center; }
  @media screen and (max-width: 499px) {
    .Method-pager {
      width: 80vw;
      margin: 6.66667vw auto 0; }
      .Method-pager .arrow {
        width: 8.53333vw;
        height: 14.4vw;
        margin-top: 4vw; }
      .Method-pager .center {
        width: 58.66667vw;
        text-align: center;
        margin: 0 auto;
        font-size: 4vw;
        line-height: 1.3889; }
        .Method-pager .center .num {
          font-size: 5.86667vw; } }

.flex-box-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 2.92826vw; }
  .flex-box-content.two img {
    display: block;
    width: 34.77306vw; }
  .flex-box-content.three {
    margin-top: 3.66032vw; }
    .flex-box-content.three img {
      display: block;
      width: 21.96193vw; }
  @media screen and (max-width: 499px) {
    .flex-box-content {
      display: block;
      margin-top: 8vw; }
      .flex-box-content.two img {
        width: 100%; }
      .flex-box-content.two img + img {
        margin-top: 2.66667vw; }
      .flex-box-content.three {
        margin-top: 2.66667vw; }
        .flex-box-content.three img {
          width: 100%; }
        .flex-box-content.three img + img {
          margin-top: 2.66667vw; } }

.sec-Opencampus {
  margin-top: 6.22255vw; }
  .sec-Opencampus .lead-under {
    text-align: center;
    margin-top: 0.87848vw; }
  .sec-Opencampus .school-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: -2.92826vw; }
    .sec-Opencampus .school-list li {
      width: 35.13909vw;
      margin-left: 2.92826vw;
      margin-top: 4.75842vw; }
      .sec-Opencampus .school-list li figure {
        width: 100%;
        height: 17.56955vw;
        overflow: hidden; }
      .sec-Opencampus .school-list li .name {
        width: 100%;
        color: #fff;
        font-size: 1.0981vw;
        font-weight: bold;
        letter-spacing: 0.1em;
        padding: 0.95168vw 1.0981vw;
        background-image: url(../../assets/img/common/arrow_triangle_white.svg);
        background-position: right 1.1713vw center;
        background-size: 0.61127vw 0.87848vw;
        background-repeat: no-repeat; }
      @media screen and (min-width: 768px) {
        .sec-Opencampus .school-list li a:hover img {
          transform: scale(1.05); } }

.u-transition {
  transition: 0.2s; }

/**
* その他
**/
.sec-Other {
  margin-top: 7.32064vw; }
  .sec-Other .unbar-title-secandly {
    margin-top: 3.66032vw; }
    @media screen and (max-width: 499px) {
      .sec-Other .unbar-title-secandly {
        margin-top: 13.33333vw; } }
  .sec-Other .text-title-secandly {
    margin-top: 2.56223vw; }
    @media screen and (max-width: 499px) {
      .sec-Other .text-title-secandly {
        margin-top: 8vw; } }
  .sec-Other ul {
    font-size: 1.02489vw;
    line-height: 2;
    letter-spacing: 0.05em; }
    @media screen and (max-width: 499px) {
      .sec-Other ul {
        font-size: 3.2vw; } }
  .sec-Other .counter-image {
    width: 36.60322vw;
    margin: 3.66032vw auto 0; }
    .sec-Other .counter-image .tit {
      font-weight: bold;
      font-size: 1.1713vw;
      text-align: center;
      margin-bottom: 1.83016vw; }
    @media screen and (max-width: 499px) {
      .sec-Other .counter-image {
        width: 100%;
        margin: 8vw auto 0; }
        .sec-Other .counter-image .tit {
          font-size: 3.73333vw;
          margin-bottom: 5.33333vw; } }

.sec-targetLink {
  margin-top: 10.2489vw; }
  @media screen and (max-width: 499px) {
    .sec-targetLink {
      margin-top: 26.66667vw; } }
  .sec-targetLink .title {
    font-size: 1.75695vw;
    letter-spacing: 0.2em;
    padding-left: 0.2em;
    text-align: center; }
    @media screen and (max-width: 499px) {
      .sec-targetLink .title {
        font-size: 4.8vw;
        line-height: 1.7;
        letter-spacing: 0.1em;
        padding-left: 0.1em; } }
  .sec-targetLink .lead {
    font-size: 1.31772vw;
    letter-spacing: 0.1em;
    padding-left: 0.1em;
    text-align: center;
    line-height: 2;
    margin-top: 1.46413vw;
    margin-bottom: 1.0981vw; }
    @media screen and (max-width: 499px) {
      .sec-targetLink .lead {
        font-size: 4vw;
        letter-spacing: 0.05em;
        padding-left: 0em;
        text-align: left;
        margin-top: 2.66667vw;
        margin-bottom: 2.66667vw; } }
  .sec-targetLink .text {
    text-align: center; }
  .sec-targetLink .school-list {
    margin-top: -3.07467vw;
    display: flex;
    flex-wrap: wrap;
    margin-left: -1.46413vw; }
    .sec-targetLink .school-list > li {
      width: 17.20351vw;
      margin-left: 1.46413vw;
      padding-top: 5.12445vw; }
      .sec-targetLink .school-list > li h4 {
        font-size: 1.0981vw;
        font-weight: bold;
        line-height: 1.4667;
        margin-top: 1.1713vw;
        letter-spacing: 0.05em; }
    @media screen and (max-width: 499px) {
      .sec-targetLink .school-list {
        margin-top: 2.66667vw;
        margin-left: -2.66667vw; }
        .sec-targetLink .school-list > li {
          width: 50%;
          margin-left: 0;
          position: relative;
          padding-top: 8vw;
          padding-left: 2.66667vw; }
          .sec-targetLink .school-list > li h4 {
            font-size: 3.46667vw;
            margin-top: 3.2vw; }
          .sec-targetLink .school-list > li .p-target-linkbox.Method {
            position: static; } }

table.info-table td {
  padding-left: 1em; }

/*nav*/
.Scholarship-nav ul {
  display: flex;
  border-top: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc; }
  .Scholarship-nav ul li {
    width: 50%; }
    .Scholarship-nav ul li a {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 3.51391vw;
      font-size: 1.02489vw;
      line-height: 2;
      letter-spacing: 0.1em; }
    .Scholarship-nav ul li.current a {
      color: #fff;
      background: #f7931e;
      pointer-events: none; }
    .Scholarship-nav ul li + li a {
      border-left: 1px solid #cccccc; }

@media screen and (max-width: 499px) {
  .Scholarship-nav ul li a {
    height: 10.66667vw;
    font-size: 3.46667vw; } }

.scla-head-area {
  margin-top: 7.32064vw;
  margin-bottom: 9.51684vw; }
  .scla-head-area .upper {
    font-size: 1.0981vw;
    letter-spacing: 0.05em;
    padding-left: 0.05em;
    text-align: center; }
  .scla-head-area .tit {
    font-size: 2.34261vw;
    letter-spacing: 0.2em;
    padding-left: 0.2em;
    line-height: 3.66032vw;
    text-align: center;
    margin-top: 0.73206vw; }
  .scla-head-area .lead {
    width: 73.20644vw;
    margin: 3.29429vw auto 0;
    font-size: 1.46413vw;
    letter-spacing: 0.15em;
    padding-left: 0.15em;
    line-height: 2;
    text-align: center; }
  @media screen and (max-width: 499px) {
    .scla-head-area {
      margin-top: 13.33333vw;
      margin-bottom: 18.66667vw; }
      .scla-head-area .upper {
        font-size: 3.46667vw; }
      .scla-head-area .tit {
        font-size: 5.33333vw;
        letter-spacing: 0.1em;
        padding-left: 0.1em;
        line-height: 9.33333vw;
        text-align: center;
        margin-top: 1.86667vw; }
      .scla-head-area .lead {
        width: 100%;
        margin: 6.66667vw auto 0;
        font-size: 4.26667vw;
        line-height: 1.8; } }

.sec-Scholarship {
  margin-top: 2.92826vw; }
  @media screen and (max-width: 499px) {
    .sec-Scholarship {
      margin-top: 8vw; } }
  .sec-Scholarship .p-list-circle-number {
    font-size: 1.02489vw;
    line-height: 2;
    letter-spacing: .1em; }
    @media screen and (max-width: 499px) {
      .sec-Scholarship .p-list-circle-number {
        font-size: 3.2vw; } }
  .sec-Scholarship .p-list-asterisk {
    font-size: 0.87848vw;
    line-height: 2;
    letter-spacing: .1em;
    margin-top: 0.21962vw; }
    @media screen and (max-width: 499px) {
      .sec-Scholarship .p-list-asterisk {
        font-size: 3.2vw; } }
  .sec-Scholarship .brand-box-title {
    margin: 1.31772vw 0 0.87848vw; }
    .sec-Scholarship .brand-box-title.second {
      margin-top: 2.56223vw; }
    @media screen and (max-width: 499px) {
      .sec-Scholarship .brand-box-title {
        margin: 4.8vw 0 2.13333vw; }
        .sec-Scholarship .brand-box-title.second {
          margin-top: 8vw; } }
  .sec-Scholarship .colmun-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-left: -3.66032vw; }
  .sec-Scholarship .colmun {
    width: 50%;
    padding-left: 3.66032vw; }
    @media screen and (max-width: 499px) {
      .sec-Scholarship .colmun {
        width: 100%; }
        .sec-Scholarship .colmun + .colmun {
          margin-top: 5.33333vw; }
        .sec-Scholarship .colmun + .colmun.second {
          margin-top: 0vw; } }
  .sec-Scholarship .content-tit {
    margin-top: 0.73206vw; }
  .sec-Scholarship .mt-spacer {
    margin-top: 1.46413vw; }
    @media screen and (max-width: 499px) {
      .sec-Scholarship .mt-spacer {
        margin-top: 5.33333vw; } }
  .sec-Scholarship.sec-spacer {
    margin-top: 4.75842vw;
    padding-top: 5.49048vw;
    border-top: 1px solid #c8c8c8; }
    @media screen and (max-width: 499px) {
      .sec-Scholarship.sec-spacer {
        margin-top: 10.66667vw;
        padding-top: 10.66667vw; } }

.sec-Scholarship.lower.sec-spacer {
  padding-top: 4.39239vw; }
  @media screen and (max-width: 499px) {
    .sec-Scholarship.lower.sec-spacer {
      border: 0;
      margin-top: 0;
      padding-top: 10.66667vw; } }
  .sec-Scholarship.lower.sec-spacer .lead {
    font-size: 1.1713vw;
    line-height: 2.56223vw;
    letter-spacing: 0.15em;
    padding-left: 0.15em;
    font-weight: bold;
    text-align: center; }
    @media screen and (max-width: 499px) {
      .sec-Scholarship.lower.sec-spacer .lead {
        font-size: 3.73333vw;
        line-height: 2;
        letter-spacing: 0.1em; } }
  .sec-Scholarship.lower.sec-spacer .download-list {
    margin-top: 0.73206vw;
    margin-bottom: 2.92826vw; }
    @media screen and (max-width: 499px) {
      .sec-Scholarship.lower.sec-spacer .download-list {
        margin-top: 2.66667vw;
        margin-bottom: 8vw; } }
    .sec-Scholarship.lower.sec-spacer .download-list li a {
      position: relative; }
  .sec-Scholarship.lower.sec-spacer .icf-target {
    position: absolute;
    right: 1.0981vw; }
    @media screen and (max-width: 499px) {
      .sec-Scholarship.lower.sec-spacer .icf-target {
        right: 3.2vw; } }
  .sec-Scholarship.lower.sec-spacer .app-area {
    margin-top: 1.46413vw; }
    .sec-Scholarship.lower.sec-spacer .app-area .item {
      display: flex;
      justify-content: center;
      align-items: center; }
      .sec-Scholarship.lower.sec-spacer .app-area .item .image {
        width: 11.71303vw; }
        .sec-Scholarship.lower.sec-spacer .app-area .item .image img {
          width: 100%; }
      .sec-Scholarship.lower.sec-spacer .app-area .item .text {
        width: 11.71303vw;
        margin-left: 1.46413vw;
        letter-spacing: 0.15em; }
        .sec-Scholarship.lower.sec-spacer .app-area .item .text img {
          width: 100%; }
      .sec-Scholarship.lower.sec-spacer .app-area .item + .item {
        margin-top: 1.46413vw; }
    @media screen and (max-width: 499px) {
      .sec-Scholarship.lower.sec-spacer .app-area {
        margin-top: 2.66667vw; }
        .sec-Scholarship.lower.sec-spacer .app-area .item .image {
          width: 42.66667vw; }
          .sec-Scholarship.lower.sec-spacer .app-area .item .image img {
            width: 100%; }
        .sec-Scholarship.lower.sec-spacer .app-area .item .text {
          width: 26.66667vw;
          margin-left: 2.66667vw; }
        .sec-Scholarship.lower.sec-spacer .app-area .item + .item {
          margin-top: 2.66667vw; } }

.scholarship_table {
  width: 100%;
  font-size: 1.0981vw;
  line-height: 1.83016vw;
  letter-spacing: 0.05em;
  margin-top: 0.73206vw;
  margin-bottom: 1.0981vw; }
  .scholarship_table th, .scholarship_table td {
    padding: 0.87848vw 1.83016vw;
    text-align: center; }
  .scholarship_table th {
    color: #fff;
    font-weight: normal;
    background: #f7931e;
    border: 1px solid #f7931e; }
  .scholarship_table td {
    border: 1px solid #f7931e; }
  .scholarship_table.scholarship_table_01 th {
    border-right: 1px solid #fff; }
    .scholarship_table.scholarship_table_01 th:nth-child(3) {
      border-right: 1px solid #f7931e; }
  .scholarship_table.scholarship_table_02 th {
    border-bottom: 1px solid #fff; }
  .scholarship_table.scholarship_table_02 tr:nth-last-child(1) th {
    border-bottom: 1px solid #f7931e; }
  @media screen and (max-width: 499px) {
    .scholarship_table {
      font-size: 3.46667vw;
      line-height: 1.7;
      letter-spacing: 0.05em;
      margin-top: 2.66667vw;
      margin-bottom: 4vw; }
      .scholarship_table th, .scholarship_table td {
        padding: 3.2vw 2.66667vw;
        text-align: center; }
      .scholarship_table.scholarship_table_01 th:nth-child(1) {
        width: 29.33333vw; }
      .scholarship_table.scholarship_table_02 th, .scholarship_table.scholarship_table_02 td {
        padding: 2.66667vw 2.66667vw;
        text-align: center; } }

/*.under-table {
    margin-top: _vw(15);
}*/
.scla-simu-wrapper {
  margin-top: 2.19619vw; }
  @media screen and (max-width: 499px) {
    .scla-simu-wrapper {
      border: 0;
      margin-top: 5.33333vw;
      padding: 2.66667vw 0 13.33333vw;
      border-bottom: 1px solid #ccc; } }
  .scla-simu-wrapper .tit {
    display: flex;
    justify-content: space-between; }
    .scla-simu-wrapper .tit .name {
      width: 38%;
      padding: 1.0981vw 1.0981vw;
      text-align: center;
      font-size: 1.31772vw;
      font-weight: bold;
      color: #fff;
      line-height: 1;
      letter-spacing: 0.1em; }
    .scla-simu-wrapper .tit .case {
      font-size: 1.0981vw;
      width: 62%;
      letter-spacing: 0.1em;
      padding: 1.1713vw 1.46413vw 1.1713vw 2.19619vw; }
    @media screen and (max-width: 499px) {
      .scla-simu-wrapper .tit {
        display: block; }
        .scla-simu-wrapper .tit .name {
          display: block;
          width: 100%;
          padding: 3.2vw 2.66667vw;
          font-size: 3.73333vw; }
        .scla-simu-wrapper .tit .case {
          display: block;
          width: 100%;
          font-size: 3.46667vw;
          padding: 2.13333vw 2.66667vw 2.13333vw 2.66667vw;
          text-align: center;
          line-height: 1.5; } }
  .scla-simu-wrapper .fee {
    font-size: 1.31772vw;
    letter-spacing: 0.05em;
    text-align: center;
    margin-top: 3.29429vw;
    margin-bottom: 2.19619vw; }
    .scla-simu-wrapper .fee dt {
      font-weight: bold; }
    .scla-simu-wrapper .fee dd {
      margin-top: 1.31772vw; }
    @media screen and (max-width: 499px) {
      .scla-simu-wrapper .fee {
        font-size: 3.73333vw;
        margin-top: 5.33333vw;
        margin-bottom: 4vw; }
        .scla-simu-wrapper .fee dt {
          font-size: 4vw; }
        .scla-simu-wrapper .fee dd {
          margin-top: 2.66667vw;
          font-feature-settings: "palt" 1; } }
  .scla-simu-wrapper .pay {
    font-size: 1.0981vw;
    letter-spacing: 0.1em;
    padding-left: 0.1em;
    text-align: center; }
    .scla-simu-wrapper .pay b {
      font-size: 1.31772vw; }
    @media screen and (max-width: 499px) {
      .scla-simu-wrapper .pay {
        font-size: 3.46667vw; }
        .scla-simu-wrapper .pay b {
          font-size: 4vw; } }

.scla-simu-wrapper + .scla-simu-wrapper {
  margin-top: 5.12445vw; }
  @media screen and (max-width: 499px) {
    .scla-simu-wrapper + .scla-simu-wrapper {
      margin-top: 12vw; } }

.scholarship-case_table {
  width: 100%;
  font-size: 1.0981vw;
  line-height: 1.83016vw;
  letter-spacing: 0.05em;
  margin-top: 0.73206vw;
  margin-bottom: 1.0981vw; }
  .scholarship-case_table th, .scholarship-case_table td {
    padding: 0.87848vw 1.83016vw;
    text-align: center;
    border: 1px solid #f7931e; }
  .scholarship-case_table thead th {
    color: #fff;
    background: #f7931e; }
  .scholarship-case_table tbody th {
    color: #fff;
    background: url("../../assets/img/common/bg_digline_white_30.png") left top/30px 30px repeat;
    background-color: #fbb03b; }
  .scholarship-case_table tbody td {
    text-align: right; }
  .scholarship-case_table .total {
    font-weight: bold; }
  @media screen and (max-width: 499px) {
    .scholarship-case_table {
      font-size: 3.46667vw;
      line-height: 1.5;
      letter-spacing: 0.05em;
      margin-top: 1.33333vw;
      margin-bottom: 2.66667vw; }
      .scholarship-case_table th, .scholarship-case_table td {
        padding: 2.13333vw 2.66667vw;
        text-align: center; } }

.Scholarship-table td.example {
  vertical-align: top; }

/**アコーディオン**/
.Acc-click {
  cursor: pointer; }
  .Acc-click:nth-child(n+2) {
    margin-top: 1.46413vw; }
  .Acc-click .case {
    position: relative; }
  .Acc-click .case::after {
    content: '';
    width: 1.0981vw;
    height: 0.65886vw;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 1.31772vw;
    margin: auto 0;
    background: url("../../assets/img/common/acc_arrow.svg") no-repeat;
    transition: transform 0.5s; }
    @media screen and (max-width: 499px) {
      .Acc-click .case::after {
        width: 2.66667vw;
        height: 1.6vw;
        right: 4vw; } }
  .Acc-click.Active .case::after {
    transform: rotate(180deg); }
  @media screen and (min-width: 768px) {
    .Acc-click:hover .name {
      opacity: 0.7; } }
  @media screen and (max-width: 499px) {
    .Acc-click:nth-child(n+2) {
      margin-top: 4vw; } }

.Acc-target {
  display: none;
  padding-bottom: 2.19619vw; }
