// Object - page - Company

.sec-Company {
    margin-top: _vw(100);
    @include _mq-down(sm) {
        margin-top: _vw(70, 375);
    }
    .content-title-under {
        font-size: _vw(15);
        text-align: center;
        margin: _vw(15) auto 0;
        @include _mq-down(sm) {
            font-size: _vw(13, 375) !important;
            margin: _vw(15, 375) auto 0;
            text-align: justify;
        }
    }
    .company-content {
        margin-top: _vw(70);
        @include _mq-down(sm) {
            margin-top: _vw(50, 375);
        }
        .text-title-secandly {
            text-align: center;
        }
        p {
            margin-top: _vw(15);
            text-align: center;
            @include _mq-down(sm) {
                margin-top: _vw(10, 375);
                text-align: justify;
            }
        }
    }
    .career-map {
        border-top: 1px solid #c8c8c8;
        margin-top: _vw(25);
        padding-top: _vw(20);
        padding-bottom: _vw(15);
        @include _mq-down(sm) {
            margin-top: _vw(25, 375);
            padding-top: _vw(20, 375);
            padding-bottom: _vw(15, 375);
        }
        .banner {
            width: _vw(216);
            margin: _vw(20) auto 0;
            img {
                width: 100%;
            }
            @include _mq-down(sm) {
                width: _vw(216, 375);
                margin: _vw(20, 375) auto 0;
            }
        }
        .download-list {
            margin-top: _vw(0);
        }
        .text {
            //margin-top: _vw(15);
            text-align: center;
        }
        .career-map-box {
            margin: _vw(40) auto 0;
            width: _vw(666);
            border: 1px solid $color-brand;
            padding: _vw(30) 0 0 0;
            .tit {
                font-size: _vw(16);
                font-weight: bold;
                letter-spacing: 0.05em;
                text-align: center;
                //background: #eee;
            }
            .inr {
                margin-top: _vw(20);
                padding: 0 _vw(30) 0;
            }
            .process-list {
                display: flex;
                margin-top: _vw(5);
                margin-bottom: _vw(10);
                li {
                    font-size: _vw(14);
                    span {
                        border: 1px solid #CCC;
                        padding: _vw(10);
                        width: _vw(180);
                        text-align: center;
                        display: block;
                    }
                    & + li {
                        padding-left: _vw(27);
                        margin-left: _vw(5);
                        background: url("../../assets/img/common/process_arrow.svg") left center / _vw(22) _vw(10) no-repeat;
                    }
                }
            }
            .p-list-asterisk {
                font-size: _vw(12);
                margin-top: _vw(20);
                line-height: 2;
            }
            .contact {
                background: #eee;
                padding: _vw(25) _vw(30) _vw(17);
                margin-top: _vw(25);
                p {
                    text-align: center;
                    margin-top: _vw(8);
                }
            }
            @include _mq-down(sm) {
                margin: _vw(30, 375) auto 0;
                width: 100%;
                padding: _vw(30, 375) 0 0 0;
                .tit {
                    font-size: _vw(14, 375);
                }
                .inr {
                    margin-top: _vw(10, 375);
                    padding: 0 _vw(20, 375) 0;
                }
                .num {
                    text-align: center;
                    font-size: _vw(14, 375) !important;
                    margin-top: _vw(15, 375);
                }
                .process-list {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    margin-top: _vw(8, 375);
                    margin-bottom: _vw(10, 375);
                    li {
                        font-size: _vw(12, 375);
                        span {
                            padding: _vw(10, 375);
                            width: _vw(180, 375);
                        }
                        & + li {
                            padding-top: _vw(18, 375);
                            margin-top: _vw(8, 375);
                            padding-left: 0;
                            margin-left: 0;
                            background: url("../../assets/img/common/process_arrow_sp.svg") center top / _vw(10, 375) _vw(12, 375) no-repeat;
                        }
                    }
                }
                .p-list-asterisk {
                    font-size: _vw(12, 375);
                    margin-top: _vw(20, 375);
                    line-height: 2;
                }
                .contact {
                    background: #eee;
                    padding: _vw(18, 375) _vw(20, 375) _vw(15, 375);
                    margin-top: _vw(20, 375);
                    .tit {
                        line-height: 1.5;
                    }
                    p {
                        text-align: center;
                        margin-top: _vw(5, 375);
                        line-height: 1.7 !important;
                    }
                }
            }
        }
    }
}

    .download-list {
        width: _vw(620);
        margin: _vw(30) auto 0;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        @include _mq-down(sm) {
            width: 100%;
            margin: _vw(20, 375) auto 0;
        }
        li {
            margin-top: _vw(20);
            a {
                display: flex;
                justify-content: center;
                align-items: center;
                width: _vw(300);
                height: _vw(60);
                background: linear-gradient(to right, #ffbe55, $color-brand);
                color: #fff;
                font-size: _vw(14);
                line-height: 1.5714;
                letter-spacing: 0.1em;
                padding-left: 0.1em;
                text-align: center;
            }
            @include _mq-up(sm) {
                &:nth-child(even) {
                    margin-left: _vw(20);
                }
            }
            @include _mq-down(sm) {
                margin-top: _vw(10, 375);
                a {
                    width: _vw(250, 375);
                    height: _vw(50, 375);
                    font-size: _vw(12, 375);
                    line-height: 1.4;
                }
            }
        }
    }

.sec-License {
    margin-top: _vw(140);
    @include _mq-down(sm) {
        margin-top: _vw(100, 375);
    }
    .p-list-asterisk {
        margin-top: _vw(20);
        font-size: 12px;
        text-align: center;
        line-height: 1.7;
        @include _mq-down(sm) {
            margin-top: _vw(20, 375);
            text-align: left;
        }
    }
    .license-lead {
        .text-title-secandly {
            text-align: center;
            margin-top: _vw(30);
            @include _mq-down(sm) {
                margin-top: _vw(20, 375);
            }
        }
        p {
            margin-top: _vw(15);
            text-align: center;
            @include _mq-down(sm) {
                margin-top: _vw(10, 375);
                text-align: justify;
            }
        }
    }
    .license-content {
        margin-top: _vw(50);
        @include _mq-down(sm) {
            margin-top: _vw(50, 375);
        }
        .mt-spacer {
            margin-top: _vw(45);
            @include _mq-down(sm) {
                margin-top: _vw(30, 375);
            }
        }
        p {
            margin-top: _vw(15);
            @include _mq-down(sm) {
                margin-top: _vw(10, 375);
            }
        }
        @include _mq-down(sm) {
            .unbar-title-secandly span {
                display: block;
                font-size: _vw(14, 375);
            }
        }
        .text-title-secandly {
            margin-top: _vw(40);
            @include _mq-down(sm) {
                margin-top: _vw(30, 375);
            }
        }
        .lisence-list {
            font-size: _vw(14);
            line-height: 2;
            letter-spacing: 0.1em;
            padding-left: 1em;
            margin-top: _vw(12);
            @include _mq-up(xxl) {
                font-size: 14px;
                margin-top: 12px;
            }
            @include _mq-down(sm) {
                font-size: _vw(12, 375);
                letter-spacing: 0.05em;
                padding-left: 0;
                margin-top: _vw(10, 375);
            }
            .course {
                font-weight: bold;
                position: relative;
                padding-left: 0.1em;
                &:before {
                    content: '';
                    position: absolute;
                    left: -0.75em;
                    top: 0;
                    bottom: 0;
                    margin: auto 0;
                    width: 5px;
                    height: 1px;
                    background: #000;
                }
                @include _mq-down(sm) {
                    font-size: _vw(14, 375);
                    padding-left: 1em;
                    &:before {
                        left: 0.2em;
                    }
                }
            }
        }
    }
    .license-content + .license-content {
        margin-top: _vw(65);
        padding-top: _vw(65);
        border-top: 1px solid #c8c8c8;
        @include _mq-down(sm) {
            margin-top: _vw(50, 375);
            padding-top: _vw(50, 375);
        }
    }

}





