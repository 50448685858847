// Object - page - about

.sec-About_head {
    background: url("../../assets/img/about/bg_about_head.jpg") center center / cover no-repeat;
    height: _vw(840);
    @include _mq-down(sm) {
        height: auto;
    }
    .content-head {
        position: relative;
        &:before {
            content: '';
            width: _vw(100);
            height: _vw(50);
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            margin: 0 auto;
            background: url("../../assets/img/about/bg_triangle_01.png") center top / cover no-repeat;
        }
        .title {
            width: _vw(396);
            margin: 0 auto;
            padding: _vw(120) 0 _vw(100);
            @include _mq-down(sm) {
                width: _vw(250, 375);
                padding: _vw(60, 375) 0 _vw(50, 375);
            }
        }
        .lead-title-wrap {
            text-align: center;
        }
        .lead-title {
            font-size: _vw(20);
            letter-spacing: 0.3em;
            padding: 0 _vw(97) 0 _vw(103);
            color: $color-brand;
            position: relative;
            display: inline-block;
            &::before, &::after {
                content: '';
                width: _vw(77);
                height: 1px;
                background: $color-brand;
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto 0;
            }
            &::before {
                left: 0;
            }
            &::after {
                right: 0;
            }
            @include _mq-down(sm) {
                font-size: _vw(15, 375);
                letter-spacing: 0.15em;
            }
        }
        .lead {
            font-size: _vw(31.5);
            text-align: center;
            letter-spacing: 0.15em;
            padding-left: 0.15em;
            line-height: 2;
            margin-top: _vw(40);
            @include _mq-down(sm) {
                font-size: _vw(14, 375);
                letter-spacing: 0.05em;
                padding-left: 0.05em;
                margin-top: _vw(20, 375);
            }
        }
        .about-list-wrap {
            background: linear-gradient(to right, rgba($color-brand, 0) 0%, rgba($color-brand, 0.5) 5%, rgba($color-brand, 1) 15%, rgba($color-brand, 1) 85%, rgba($color-brand, 0.5) 95%, rgba($color-brand, 0) 100%);
            padding: _vw(30) _vw(85);
            width: _vw(1166);
            margin: _vw(35) auto 0;
            @include _mq-down(sm) {
                background: linear-gradient(to right, rgba($color-brand, 0) 0%, rgba($color-brand, 0.5) 10%, rgba($color-brand, 1) 25%, rgba($color-brand, 1) 75%, rgba($color-brand, 0.5) 90%, rgba($color-brand, 0) 100%);
                margin: _vw(20, 375) auto 0;
            }
        }
        .about-list {
            display: flex;
            li {
                font-size: _vw(20);
                text-align: center;
                padding: _vw(3) 0;
                @include _mq-up(sm) {
                    & + li {
                        border-left: 1px solid #fff;
                    }
                }
                a {
                    color: #fff;
                    width: _vw(330);
                    display: block;
                    letter-spacing: 0.1em;
                    span {
                        font-size: _vw(13);
                        display: block;
                        letter-spacing: 0.1em;
                        margin-top: _vw(8);
                    }
                }
            }
            @include _mq-down(sm) {
                display: block;
                padding: _vw(10, 375) 0;
                li {
                    font-size: _vw(15, 375);
                    width: 60%;
                    margin-right: auto;
                    margin-left: auto;
                    & + li {
                        margin-top: _vw(10, 375);
                        padding-top: _vw(10, 375);
                        border-top: 1px solid rgba(255,255,255,0.5);
                    }
                    a {
                        width: 100%;
                        span {
                            font-size: _vw(10, 375);
                            margin-top: _vw(6, 375);
                        }
                    }
                }
            }
        }
    }
    .under-arrow {
        width: _vw(61);
        margin: _vw(95) auto 0;
    }
}

.sec-About {
    .content-box {
        width: 100%;
        height: _vw(600);
        border: _vw(10) solid #f0f0f0;
        padding: _vw(82) _vw(100) _vw(140);
        position: relative;
        &::before, &::after {
            content: '';
            width: 1px;
            height: _vw(100);
            background: $color-brand;
            position: absolute;
            left: 0;
            right: 0;
            margin: 0 auto;
        }
        &::before {
            top: _vw(-55);
        }
        &::after {
            bottom: _vw(-55);
        }
        @include _mq-down(sm) {
            border: _vw(5, 375) solid #f0f0f0;
        }
    }
    .title {
        font-size: _vw(33);
        letter-spacing: 0.1em;
        text-align: center;
        span {
            display: block;
            color: $color-brand;
            font-size: _vw(20);
            letter-spacing: 0.1em;
            margin-top: _vw(14);
        }
        @include _mq-down(sm) {
            font-size: _vw(16, 375);
            span {
                font-size: _vw(10, 375);
                margin-top: _vw(5, 375);
            }
        }
    }
}

.sec-About_01 {
    position: relative;
    &::before {
        content: '';
        width: _vw(359);
        height: _vw(363);
        position: absolute;
        top: _vw(-282);
        left: _vw(-134);
        background: url("../../assets/img/about/bg_triangle_02.png") center top / cover no-repeat;
    }
    &::after {
        content: '';
        width: _vw(183);
        height: _vw(185);
        position: absolute;
        top: _vw(-300);
        right: 0;
        background: url("../../assets/img/about/bg_triangle_03.png") center top / cover no-repeat;
    }
    .content-box {
        //height: _vw(600);
        height: auto;
        padding: _vw(82) _vw(100) _vw(140);
        background: #fff;
        .lead {
            font-size: _vw(23);
            letter-spacing: 0.1em;
            text-align: center;
            margin-top: _vw(65);
        }
        .image-area {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-top: _vw(75);
            figure {
                display: block;
                width: _vw(175);
            }
        }
        @include _mq-down(sm) {
            padding: _vw(40, 375) _vw(30, 375) _vw(35, 375);
            .lead {
                font-size: _vw(13, 375);
                letter-spacing: 0.05em;
                text-align: center;
                margin-top: _vw(20, 375);
                line-height: 1.5;
            }
            .image-area {
                margin-top: _vw(20, 375);
                figure {
                    display: block;
                    width: _vw(130, 375);
                }
                figure:nth-child(n+3) {
                    margin-top: _vw(5, 375);
                }
            }
        }
    }
}

.sec-About_02 {
    margin-top: _vw(200);
    position: relative;
    &::before {
        content: '';
        width: _vw(481);
        height: _vw(486);
        position: absolute;
        top: _vw(-282);
        right: _vw(-53);
        background: url("../../assets/img/about/bg_triangle_04.png") center center / cover no-repeat;
        z-index: -1;
    }
    &::after {
        content: '';
        width: _vw(616);
        height: _vw(623);
        position: absolute;
        bottom: _vw(-414);
        left: _vw(-134);
        background: url("../../assets/img/about/bg_triangle_02.png") center center / cover no-repeat;
        z-index: -1;
    }
    .content-box {
        //height: _vw(1110);
        height: auto;
        padding: _vw(82) _vw(140) _vw(140);
        background: #fff;
        .lead {
            font-size: _vw(23);
            line-height: _vw(84);
            margin: _vw(65) auto 0;
            text-align: center;
            span {
                display: block;
                & + span {
                    border-top: 1px solid #b3b3b3;
                }
            }
        }
        .image-area {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-top: _vw(75);
            figure {
                display: block;
                width: _vw(167.5);
            }
            @include _mq-up(sm) {
                figure:nth-child(n+5) {
                    margin-top: _vw(10);
                }
            }
        }
        @include _mq-down(sm) {
            padding: _vw(40, 375) _vw(20, 375) _vw(35, 375);
            .lead {
                font-size: _vw(12, 375);
                line-height: _vw(35, 375);
                margin: _vw(15, 375) auto 0;
            }
            .image-area {
                margin-top: _vw(15, 375);
                figure {
                    display: block;
                    width: _vw(67, 375);
                    margin-left: _vw(5, 375);
                }
                figure:nth-child(4n+1) {
                    margin-left: 0;
                }
                figure:nth-child(n+5) {
                    margin-top: _vw(5, 375);
                }
            }
        }
    }
}

.sec-About_03 {
    margin-top: _vw(90);
    padding-top: _vw(70);
    position: relative;
    &::before {
        content: '';
        width: _vw(216);
        height: _vw(218);
        position: absolute;
        top: _vw(690);
        right: 0;
        background: url("../../assets/img/about/bg_triangle_06.png") center center / cover no-repeat;
        z-index: -1;
    }
    @include _mq-down(sm) {
        margin-top: _vw(0, 375);
        padding-top: _vw(70, 375);
    }
    .content-inr {
        margin-top: _vw(70);
        display: inline-block;
    }
    .text {
        margin-top: _vw(-7);
        width: _vw(594);
        font-size: _vw(15);
        line-height: 2;
        float: left;
        span {
            display: inline-block;
        }
        .mt-spacer {
            padding-top: 2em;
        }
        @include _mq-down(sm) {
            margin-top: _vw(10, 375);
            width: 100%;
            float: none;
            font-size: _vw(13, 375);
            text-align: justify;
            span {
                display: inline;
            }
        }
    }
    .image-area {
        width: _vw(350);
        float: right;
        .sch-name {
            font-size: _vw(15);
            letter-spacing: 0.05em;
            padding-left: 0.05em;
            text-align: center;
            margin-top: _vw(35);
        }
        .riji-name {
            font-size: _vw(15);
            margin-top: _vw(16);
            letter-spacing: 0.15em;
            padding-left: 0.15em;
            text-align: center;
            span {
                font-size: _vw(24);
                padding-left: 0.5em;
            }
        }
        @include _mq-down(sm) {
            width: _vw(200, 375);
            margin: _vw(40, 375) auto 0;
            float: none;
            .sch-name {
                font-size: _vw(12, 375);
                letter-spacing: 0.05em;
                padding-left: 0.05em;
                margin-top: _vw(15, 375);
            }
            .riji-name {
                font-size: _vw(12, 375);
                margin-top: _vw(10, 375);
                letter-spacing: 0.05em;
                padding-left: 0.05em;
                span {
                    font-size: _vw(16,  375);
                    padding-left: 0.5em;
                }
            }
        }
    }
}




