// Object - page - access

.sec-Access {
    margin-top: _vw(100);
    @include _mq-down(sm) {
        margin-top: _vw(50, 375);
    }
    .map {
        margin-top: _vw(30);
        @include _mq-down(sm) {
            margin-top: _vw(20, 375);
        }
        .address {
            font-size: _vw(14);
            margin-top: _vw(18);
            @include _mq-down(sm) {
                font-size: _vw(12, 375);
            }
        }
    }

    @include _mq-down(sm) {
    }
    .school-list {
        display: flex;
        flex-wrap: wrap;
        //gap: _vw(65) _vw(40);
        margin-top: _vw(10);
        margin-left: _vw(-65);
        @include _mq-down(sm) {
            margin-top: _vw(10, 375);
        }
        @include _mq-down(sm) {
            //gap: _vw(40, 375) 0%;
        }
        li {
            width: 50%;
            padding-left: _vw(65);
            margin-top: _vw(45);
            @include _mq-down(sm) {
                width: 100%;
                margin-top: _vw(20, 375);
            }
        }
        h4 {
            font-size: _vw(20);
            font-weight: bold;
            letter-spacing: 0.1em;
            a {
                padding-right: _vw(28);
                display: inline-block;
                background: url("../../assets/img/common/icon_target_orange.svg") right bottom / _vw(20.5) _vw(18.5) no-repeat;
            }
            @include _mq-down(sm) {
                font-size: _vw(16, 375);
                a {
                    padding-right: _vw(21, 375);
                    background-position: right center;
                    background-size: _vw(15.5, 375) _vw(14, 375);
                }
            }
        }
        .course {
            font-size: _vw(14);
            //font-weight: bold;
            color: $color-brand;
            letter-spacing: 0.1em;
            margin-top: _vw(23);
            @include _mq-down(sm) {
                font-size: _vw(12, 375);
                margin-top: _vw(15, 375);
            }
        }
        p {
            font-size: _vw(16);
            line-height: 1.6;
            letter-spacing: 0.1em;
            margin-top: _vw(5);
            @include _mq-down(sm) {
                margin-top: _vw(5, 375);
                font-size: _vw(14, 375);
                line-height: 1.6 !important;
            }
        }
        .close {
            h4 a {
                color: #aaa;
                pointer-events: none;
                background: none;
            }
        }
    }
}

.sec-Access.next  {
    border-top: 1px solid #c8c8c8;
    padding-top: _vw(70);
    @include _mq-down(sm) {
        padding-top: _vw(50, 375);
    }
}
