// Object - page - scholarship

/*nav*/
.Scholarship-nav {
    ul {
        display: flex;
        border-top: 1px solid #cccccc;
        border-bottom: 1px solid #cccccc;
        li {
            width: 50%;
            a {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                height: _vw(48);
                font-size: _vw(14);
                line-height: 2;
                letter-spacing: 0.1em;
            }
            &.current {
                a {
                    color: #fff;
                    background: $color-brand;
                    pointer-events: none;
                }
            }
            & + li a {
                border-left: 1px solid #cccccc;
            }
        }
    }
    @include _mq-down(sm) {
        ul {
            li {
                a {
                    height: _vw(40, 375);
                    font-size: _vw(13, 375);
                }
            }
        }
    }
}

.scla-head-area {
    margin-top: _vw(100);
    margin-bottom: _vw(130);
    .upper {
        font-size: _vw(15);
        letter-spacing: 0.05em;
        padding-left: 0.05em;
        text-align: center;
    }
    .tit {
        font-size: _vw(32);
        letter-spacing: 0.2em;
        padding-left: 0.2em;
        line-height: _vw(50);
        text-align: center;
        margin-top: _vw(10);
    }
    .lead {
        width: _vw(1000);
        margin: _vw(45) auto 0;
        font-size: _vw(20);
        letter-spacing: 0.15em;
        padding-left: 0.15em;
        line-height: 2;
        text-align: center;
    }
    @include _mq-down(sm) {
        margin-top: _vw(50, 375);
        margin-bottom: _vw(70, 375);
        .upper {
            font-size: _vw(13, 375);
        }
        .tit {
            font-size: _vw(20, 375);
            letter-spacing: 0.1em;
            padding-left: 0.1em;
            line-height: _vw(35, 375);
            text-align: center;
            margin-top: _vw(7, 375);
        }
        .lead {
            width: 100%;
            margin: _vw(25, 375) auto 0;
            font-size: _vw(16, 375);
            line-height: 1.8;
        }
    }
}

.sec-Scholarship {
    margin-top: _vw(40);
    @include _mq-down(sm) {
        margin-top: _vw(30, 375);
    }
    .p-list-circle-number {
        font-size: _vw(14);
        line-height: 2;
        letter-spacing: .1em;
        @include _mq-down(sm) {
            font-size: _vw(12, 375);
        }
    }
    .p-list-asterisk {
        font-size: _vw(12);
        line-height: 2;
        letter-spacing: .1em;
        margin-top: _vw(3);
        @include _mq-down(sm) {
            font-size: _vw(12, 375);
        }
    }
    .brand-box-title {
        margin: _vw(18) 0 _vw(12);
        &.second {
            margin-top: _vw(35)
        }
        @include _mq-down(sm) {
            margin: _vw(18, 375) 0 _vw(8, 375);
            &.second {
                margin-top: _vw(30, 375)
            }
        }
    }
    .colmun-wrapper {
        display: flex;
        flex-wrap: wrap;
        margin-left: _vw(-50);
    }
    .colmun {
        width: 50%;
        padding-left: _vw(50);
        @include _mq-down(sm) {
            width: 100%;
            & + .colmun {
                margin-top: _vw(20, 375);
            }
            & + .colmun.second {
                margin-top: _vw(0, 375);
            }
        }
    }
    .content-tit {
        margin-top: _vw(10);
    }
    .mt-spacer {
        margin-top: _vw(20);
        @include _mq-down(sm) {
            margin-top: _vw(20, 375);
        }
    }
    &.sec-spacer {
        margin-top: _vw(65);
        padding-top: _vw(75);
        border-top: 1px solid #c8c8c8;
        @include _mq-down(sm) {
            margin-top: _vw(40, 375);
            padding-top: _vw(40, 375);
        }
    }
}

.sec-Scholarship.lower.sec-spacer {
    padding-top: _vw(60);
    @include _mq-down(sm) {
        border: 0;
        margin-top: 0;
        padding-top: _vw(40, 375);
    }
    .lead {
        font-size: _vw(16);
        line-height: _vw(35);
        letter-spacing: 0.15em;
        padding-left: 0.15em;
        font-weight: bold;
        text-align: center;
        @include _mq-down(sm) {
            font-size: _vw(14, 375);
            line-height: 2;
            letter-spacing: 0.1em;
        }
    }
    .download-list {
        margin-top: _vw(10);
        margin-bottom: _vw(40);
        @include _mq-down(sm) {
            margin-top: _vw(10, 375);
            margin-bottom: _vw(30, 375);
        }
        li a {
            position: relative;
        }
    }
    .icf-target {
        position: absolute;
        right: _vw(15);
        @include _mq-down(sm) {
            right: _vw(12, 375);
        }
    }
    .app-area {
        margin-top: _vw(20);
        .item {
            display: flex;
            justify-content: center;
            align-items: center;
            .image {
                width: _vw(160);
                img {
                    width: 100%;
                }
            }
            .text {
                width: _vw(160);
                margin-left: _vw(20);
                letter-spacing: 0.15em;
                img {
                    width: 100%;
                }
            }
            & + .item {
                margin-top: _vw(20);
            }
        }
        @include _mq-down(sm) {
            margin-top: _vw(10, 375);
            .item {
                .image {
                    width: _vw(160, 375);
                    img {
                        width: 100%;
                    }
                }
                .text {
                    width: _vw(100, 375);
                    margin-left: _vw(10, 375);
                }
                & + .item {
                    margin-top: _vw(10, 375);
                }
            }
        }
    }

}

.scholarship_table {
    width: 100%;
    font-size: _vw(15);
    line-height: _vw(25);
    letter-spacing: 0.05em;
    margin-top: _vw(10);
    margin-bottom: _vw(15);
    th, td {
        padding: _vw(12) _vw(25);
        text-align: center;
    }
    th {
        color: #fff;
        font-weight: normal;
        background: $color-brand;
        border: 1px solid $color-brand;
    }
    td {
        border: 1px solid $color-brand;
    }
    &.scholarship_table_01 {
        th {
            border-right: 1px solid #fff;
            &:nth-child(3) {
                border-right: 1px solid $color-brand;
            }
        }
    }
    &.scholarship_table_02 {
        th {
            border-bottom: 1px solid #fff;
        }
        tr:nth-last-child(1) {
            th {
                border-bottom: 1px solid $color-brand;
            }
        }
    }
    @include _mq-down(sm) {
        font-size: _vw(13, 375);
        line-height: 1.7;
        letter-spacing: 0.05em;
        margin-top: _vw(10, 375);
        margin-bottom: _vw(15, 375);
        th, td {
            padding: _vw(12, 375) _vw(10, 375);
            text-align: center;
        }
        &.scholarship_table_01 {
            th:nth-child(1) {
                width: _vw(110, 375);
            }
        }
        &.scholarship_table_02 {
            th, td {
                padding: _vw(10, 375) _vw(10, 375);
                text-align: center;
            }
        }
    }
}
/*.under-table {
    margin-top: _vw(15);
}*/

.scla-simu-wrapper {
    //border: 1px solid $color-brand;
    margin-top: _vw(30);
    //padding: _vw(50) _vw(60) _vw(50);
    @include _mq-down(sm) {
        border: 0;
        margin-top: _vw(20, 375);
        padding: _vw(10, 375) 0 _vw(50, 375);
        border-bottom: 1px solid #ccc;
    }
    .tit {
        display: flex;
        justify-content: space-between;
        //border: 1px solid $color-brand;
        //height: _vw(50);
        .name {
            width: 38%;
            padding: _vw(15) _vw(15);
            text-align: center;
            font-size: _vw(18);
            font-weight: bold;
            color: #fff;
            //background: #8e8887;
            line-height: 1;
            letter-spacing: 0.1em;
        }
        .case {
            font-size: _vw(15);
            width: 62%;
            letter-spacing: 0.1em;
            padding: _vw(16) _vw(20) _vw(16) _vw(30);
        }
        @include _mq-down(sm) {
            display: block;
            .name {
                display: block;
                width: 100%;
                padding: _vw(12, 375) _vw(10, 375);
                font-size: _vw(14, 375);
            }
            .case {
                display: block;
                width: 100%;
                font-size: _vw(13, 375);
                padding: _vw(8, 375) _vw(10, 375) _vw(8, 375) _vw(10, 375);
                text-align: center;
                line-height: 1.5;
            }
        }
    }
    .fee {
        font-size: _vw(18);
        letter-spacing: 0.05em;
        text-align: center;
        margin-top: _vw(45);
        margin-bottom: _vw(30);
        dt {
            font-weight: bold;
        }
        dd {
            margin-top: _vw(18);
        }
        @include _mq-down(sm) {
            font-size: _vw(14, 375);
            margin-top: _vw(20, 375);
            margin-bottom: _vw(15, 375);
            dt {
                font-size: _vw(15, 375);
            }
            dd {
                margin-top: _vw(10, 375);
                font-feature-settings: "palt" 1;
            }
        }
    }
    .pay {
        font-size: _vw(15);
        letter-spacing: 0.1em;
        padding-left: 0.1em;
        text-align: center;
        b {
            font-size: _vw(18);
        }
        @include _mq-down(sm) {
            font-size: _vw(13, 375);
            b {
                font-size: _vw(15, 375);
            }
        }
    }
}
.scla-simu-wrapper + .scla-simu-wrapper {
    margin-top: _vw(70);
    @include _mq-down(sm) {
        margin-top: _vw(45, 375);
    }
}

.scholarship-case_table {
    width: 100%;
    font-size: _vw(15);
    line-height: _vw(25);
    letter-spacing: 0.05em;
    margin-top: _vw(10);
    margin-bottom: _vw(15);
    th, td {
        padding: _vw(12) _vw(25);
        text-align: center;
        border: 1px solid $color-brand;
    }
    thead {
        th {
            color: #fff;
            background: $color-brand;
        }
    }
    tbody {
        th {
            color: #fff;
            background: url("../../assets/img/common/bg_digline_white_30.png") left top / 30px 30px repeat;
            background-color: #fbb03b;
        }
        td {
            text-align: right;
        }
    }
    .total {
        font-weight: bold;
    }
    @include _mq-down(sm) {
        font-size: _vw(13, 375);
        line-height: 1.5;
        letter-spacing: 0.05em;
        margin-top: _vw(5, 375);
        margin-bottom: _vw(10, 375);
        th, td {
            padding: _vw(8, 375) _vw(10, 375);
            text-align: center;
        }
    }
}

.Scholarship-table {
    td.example {
        vertical-align: top;
    }
}

/**アコーディオン**/
.Acc-click {
    cursor: pointer;
    &:nth-child(n+2) {
        margin-top: _vw(20);
    }
    .case {
        position: relative;
    }
    .case::after {
        content: '';
        width: _vw(15);
        height: _vw(9);
        position: absolute;
        top: 0;
        bottom: 0;
        right: _vw(18);
        margin: auto 0;
        background: url("../../assets/img/common/acc_arrow.svg") no-repeat;
        transition: transform 0.5s;
        @include _mq-down(sm) {
            width: _vw(10, 375);
            height: _vw(6, 375);
            right: _vw(15, 375);
        }
    }
    &.Active .case::after {
        transform: rotate(180deg);
    }
    @include _mq-up(md) {
        &:hover .name {
            opacity: 0.7;
        }
    }
    @include _mq-down(sm) {
        &:nth-child(n+2) {
            margin-top: _vw(15, 375);
        }
    }
}

.Acc-target {
    display: none;
    padding-bottom: _vw(30);
}
