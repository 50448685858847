// Object - page - other

/**
* その他
**/
.sec-Other {
    margin-top: _vw(100);
    .unbar-title-secandly {
        margin-top: _vw(50);
        @include _mq-down(sm) {
            margin-top: _vw(50, 375);
        }
    }
    .text-title-secandly {
        margin-top: _vw(35);
        @include _mq-down(sm) {
            margin-top: _vw(30, 375);
        }
    }
    ul {
        font-size: _vw(14);
        line-height: 2;
        letter-spacing: 0.05em;
        @include _mq-down(sm) {
            font-size: _vw(12, 375);
        }
    }
    .counter-image {
        width: _vw(500);
        margin: _vw(50) auto 0;
        .tit {
            font-weight: bold;
            font-size: _vw(16);
            text-align: center;
            margin-bottom: _vw(25);
        }
        @include _mq-down(sm) {
            width: 100%;
            margin: _vw(30, 375) auto 0;
            .tit {
                font-size: _vw(14, 375);
                margin-bottom: _vw(20, 375);
            }
        }
    }
}

.sec-targetLink {
    margin-top: _vw(140);
    @include _mq-down(sm) {
        margin-top: _vw(100, 375);
    }
    .title {
        font-size: _vw(24);
        letter-spacing: 0.2em;
        padding-left: 0.2em;
        text-align: center;
        @include _mq-down(sm) {
            font-size: _vw(18, 375);
            line-height: 1.7;
            letter-spacing: 0.1em;
            padding-left: 0.1em;
        }
    }
    .lead {
        font-size: _vw(18);
        letter-spacing: 0.1em;
        padding-left: 0.1em;
        text-align: center;
        line-height: 2;
        margin-top: _vw(20);
        margin-bottom: _vw(15);
        @include _mq-down(sm) {
            font-size: _vw(15, 375);
            letter-spacing: 0.05em;
            padding-left: 0em;
            text-align: left;
            margin-top: _vw(10, 375);
            margin-bottom: _vw(10, 375);
        }
    }
    .text {
        text-align: center;
    }
    .school-list {
        margin-top: _vw(-42);
        display: flex;
        flex-wrap: wrap;
        //justify-content: space-between;
        margin-left: _vw(-20);
        > li {
            width: _vw(235);
            margin-left: _vw(20);
            padding-top: _vw(70);
            h4 {
                font-size: _vw(15);
                font-weight: bold;
                line-height: 1.4667;
                margin-top: _vw(16);
                letter-spacing: 0.05em;
            }
        }
        @include _mq-down(sm) {
            margin-top: _vw(10, 375);
            margin-left: _vw(-10, 375);
            > li {
                width: 50%;
                margin-left: 0;
                position: relative;
                padding-top: _vw(30, 375);
                padding-left: _vw(10, 375);
                h4 {
                    font-size: _vw(13, 375);
                    margin-top: _vw(12, 375);
                }
                .p-target-linkbox.Method {
                    position: static;
                }
            }
        }
    }
}

table.info-table {
    td {
        padding-left: 1em;
    }
}






