// Object - page - graduate

.sec-Graduate {
    margin-top: _vw(100);
    @include _mq-down(sm) {
        margin-top: _vw(70, 375);
    }
    .graduate-school-list {
        margin-top: _vw(50);
        @include _mq-down(sm) {
            margin-top: _vw(30, 375);
        }
    }
    .text-title-secandly.center {
        text-align: center;
        margin-top: _vw(70);
        @include _mq-down(sm) {
            margin-top: _vw(45, 375);
        }
    }
    .text-title-secandly.second {
        margin-top: _vw(60);
        @include _mq-down(sm) {
            margin-top: _vw(40, 375);
        }
    }
    .graduate-content {
        margin-top: _vw(70);
        @include _mq-down(sm) {
            margin-top: _vw(40, 375);
        }
    }
    .mt-spacer {
        margin-top: _vw(10);
    }
    .lbar-title {
        margin-top: _vw(40);
        @include _mq-down(sm) {
            margin-top: _vw(20, 375);
        }
    }
    ul {
        font-size: _vw(14);
        line-height: 2;
        letter-spacing: 0.05em;
        @include _mq-down(sm) {
            font-size: _vw(12, 375);
        }
    }
    .p-list-square.second {
        margin-top: 1em;
    }

}

.sec-Access + .sec-Access {
    border-top: 1px solid #c8c8c8;
    padding-top: 70px;
}
