// Object - page - opencampus

.sec-Opencampus {
    margin-top: _vw(85);
    .lead-under {
        text-align: center;
        margin-top: _vw(12);
    }
    .school-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-left: _vw(-40);
        li {
            width: _vw(480);
            margin-left: _vw(40);
            margin-top: _vw(65);
            figure {
                width: 100%;
                height: _vw(240);
                overflow: hidden;
            }
            .name {
                width: 100%;
                color: #fff;
                font-size: _vw(15);
                font-weight: bold;
                letter-spacing: 0.1em;
                padding: _vw(13) _vw(15);
                background-image: url(../../assets/img/common/arrow_triangle_white.svg);
                background-position: right _vw(16) center;
                background-size: _vw(8.35) _vw(12);
                background-repeat: no-repeat;
            }
            @include _mq-up(md) {
                a:hover {
                    img {
                        transform: scale(1.05);
                    }
                }
            }
        }
    }
}

.u-transition {
    transition: 0.2s;
}
