// Object - page - method

.sec-Method_top_01 {
    margin-top: _vw(140);
    .title {
        font-size: _vw(24);
        letter-spacing: 0.2em;
        padding-left: 0.2em;
        text-align: center;
    }
    .method-list {
        margin-top: _vw(28);
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        li {
            width: _vw(330);
            margin-left: _vw(5);
            h4 {
                font-size: _vw(20);
                text-align: center;
                margin-top: _vw(28);
                letter-spacing: 0.2em;
                padding-left: 0.2em;
            }
            .text {
                font-size: _vw(13);
                line-height: 1.9231;
                margin-top: _vw(18);
                text-align: center;
                letter-spacing: 0.1em;
                padding: 0 _vw(10);
            }
        }
        @include _mq-up(sm) {
            li:nth-child(3n+1) {
                margin-left: 0;
            }
            li:nth-child(n+4) {
                margin-top: _vw(70);
            }
        }
    }
    @include _mq-down(sm) {
        margin-top: _vw(70, 375);
        .title {
            font-size: _vw(18, 375);
            line-height: 1.7;
            letter-spacing: 0.1em;
            padding-left: 0.1em;
        }
        .method-list {
            margin-top: _vw(30, 375);
            li {
                width: 100%;
                & + li {
                    margin-top: _vw(50, 375);
                }
                h4 {
                    font-size: _vw(16, 375);
                    margin-top: _vw(20, 375);
                }
                .text {
                    font-size: _vw(13, 375);
                    margin-top: _vw(10, 375);
                    padding: 0 _vw(10, 375);
                }
            }
        }
    }
}

.sec-Method_top_02 {
    margin-top: _vw(70);
    padding-top: _vw(70);
    @include _mq-down(sm) {
        margin-top: _vw(30, 375);
        padding-top: _vw(70, 375);
    }
    .title {
        font-size: _vw(24);
        letter-spacing: 0.2em;
        padding-left: 0.2em;
        text-align: center;
        @include _mq-down(sm) {
            font-size: _vw(18, 375);
            line-height: 1.7;
            letter-spacing: 0.1em;
            padding-left: 0.1em;
        }
    }
    .school-list {
        margin-top: _vw(28);
        display: flex;
        flex-wrap: wrap;
        //justify-content: space-between;
        margin-left: _vw(-20);
        > li {
            width: _vw(235);
            margin-left: _vw(20);
            @include _mq-up(sm) {
                &:nth-child(n+5) {
                    padding-top: _vw(70);
                }
            }
            h4 {
                font-size: _vw(15);
                font-weight: bold;
                line-height: 1.4667;
                margin-top: _vw(16);
                letter-spacing: 0.05em;
            }
            .p-list-square.gray {
                font-size: _vw(13);
                letter-spacing: 0.05em;
                line-height: 1.5385;
                margin-top: _vw(10);
                min-height: _vw(40);
                & li {
                    padding-left: 1.2em;
                }
                & li:before {
                    color: #999;
                    margin-left: -1.2em;
                }
            }
        }
        @include _mq-down(sm) {
            margin-top: _vw(30, 375);
            margin-left: 0;
            > li {
                width: _vw(162.5, 375);
                margin-left: 0;
                position: relative;
                min-height: _vw(265, 375);
                &:nth-child(even) {
                    margin-left: _vw(10, 375);
                }
                h4 {
                    font-size: _vw(13, 375);
                    margin-top: _vw(12, 375);
                }
                .p-list-square.gray {
                    font-size: _vw(10, 375);
                    margin-top: _vw(5, 375);
                    min-height: _vw(30, 375);
                    letter-spacing: 0.01em;
                    & li {
                        padding-left: 1.0em;
                    }
                    & li:before {
                        margin-left: -1.0em;
                    }
                }
            }
            > li:nth-child(n+3) {
                margin-top: _vw(25, 375);
            }
            > li:nth-child(n+6) {
                min-height: _vw(285, 375);
            }
        }
    }
    .mt-spacer {
        margin-top: _vw(41);
    }
}

.p-target-linkbox.Method {
    a {
        transition: 0.2s;
        svg {
            transition: 0.2s;
        }
    }
    a:hover {
        background-color: #fff;
    }
    a.bgc-Pet-kanazawa:hover, a.bgc-Pet-fukui:hover {
        color: #29abe2;
        svg { fill: #29abe2; }
    }
    a.bgc-Pet-kango:hover {
        color: #ff85b9;
        svg { fill: #ff85b9; }
    }
    a.bgc-Bisen:hover {
        color: #c1272d;
        svg { fill: #c1272d; }
    }
    a.bgc-Pet-kanazawa:hover, a.bgc-Pet-fukui:hover {
        color: #29abe2;
        svg { fill: #29abe2; }
    }
    a.bgc-Medical:hover {
        color: #fbb03b;
        svg { fill: #fbb03b; }
    }
    a.bgc-HoteBra:hover {
        color: #1564a0;
        svg { fill: #1564a0; }
    }
    a.bgc-Sweets:hover {
        color: #754c24;
        svg { fill: #754c24; }
    }
    a.bgc-Cooking:hover {
        color: #8e8887;
        svg { fill: #8e8887; }
    }
    a.bgc-Cycle:hover {
        color: #009245;
        svg { fill: #009245; }
    }
    a.bgc-Food:hover {
        color: #000080;
        svg { fill: #000080; }
    }
    @include _mq-down(sm) {
        margin-top: _vw(10, 375);
        position: absolute;
        bottom: 0;
        right: 0;
        a {
            font-size: _vw(12, 375);
            height: _vw(35, 375);
            svg {
                margin-left: _vw(5, 375);
            }
        }
    }
}

.Method-title {
    text-align: center;
    margin-top: _vw(60);
    .c-text-Time {
        font-size: _vw(140);
        color: $color-brand;
    }
    h3 {
        font-size: _vw(35);
        letter-spacing: 0.15em;
        //margin-top: _vw(3);
        line-height: 1.4286;
    }
    div {
        font-size: _vw(20);
        letter-spacing: 0.15em;
        margin-top: _vw(16);
    }
    p {
        margin-top: _vw(18);
    }
    @include _mq-down(sm) {
        margin-top: _vw(60, 375);
        .c-text-Time {
            font-size: _vw(80, 375);
        }
        h3 {
            font-size: _vw(25, 375);
            margin-top: _vw(3, 375);
        }
        div {
            letter-spacing: 0.05em;
            font-size: _vw(14, 375);
            line-height: 1.7;
            margin-top: _vw(10, 375);
        }
    }
}

.sec-Method_01 {
    .text_ac-co {
        margin-top: _vw(100);
        margin: 0 auto;
        text-align: center;
        img {
            width: _vw(320);
        }
        p {
            width: _vw(800);
            margin: _vw(75) auto 0;
            span {
                display: inline-block;
            }
        }
        @include _mq-down(sm) {
            margin-top: _vw(70, 375);
            img {
                width: _vw(200, 375);
            }
            p {
                width: 100%;
                margin: _vw(40, 375) auto 0;
                text-align: justify;
                span {
                    display: inline;
                }
            }
        }
    }
    .company-content {
        margin-top: _vw(135);
        .rogo-list {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: _vw(23);
            @include _mq-up(sm) {
                li + li {
                    margin-left: _vw(30);
                }
            }
        }
        @include _mq-down(sm) {
            margin-top: _vw(70, 375);
            .rogo-list {
                flex-wrap: wrap;
                margin-top: _vw(20, 375);
                li.aeon img {
                    height: 35px;
                }
                li.ikk img {
                    height: 44px;
                    margin-left: _vw(20, 375);
                }
                li.hotel img {
                    height: 28px;
                    margin-top: _vw(15, 375);
                }
                li.aozora img {
                    height: 35px;
                    margin-top: _vw(15, 375);
                }
            }
        }
    }
    .voice-content {
        margin-top: _vw(135);
        .bracket {
            font-size: _vw(28);
            color: $color-brand;
            margin-top: _vw(40);
            text-align: center;
            letter-spacing: 0.1em;
        }
        .lead {
            font-size: _vw(20);
            line-height: 1.7;
            margin-top: _vw(30);
            text-align: center;
            letter-spacing: 0.1em;
            span {
                display: inline-block;
            }
        }
        @include _mq-down(sm) {
            margin-top: _vw(100, 375);
            .bracket {
                font-size: _vw(22, 375);
                margin-top: _vw(15, 375);
                line-height: 1.5;
            }
            .lead {
                font-size: _vw(15, 375);
                line-height: 2;
                margin-top: _vw(15, 375);
                span {
                    display: inline;
                }
            }
        }
        .voice-example {
            width: _vw(760);
            display: flex;
            justify-content: space-between;
            margin: _vw(45) auto 0;
            .image-area {
                width: _vw(420);
            }
            .text-area {
                width: _vw(310);
                letter-spacing: 0.1em;
                .role {
                    font-size: _vw(14);
                }
                .name {
                    font-size: _vw(25);
                    margin-top: _vw(16);
                    span {
                        font-size: _vw(15);
                    }
                }
                .work {
                    font-size: _vw(15);
                    margin-top: _vw(16);
                    line-height: 1.4;
                }
                .text {
                    font-size: _vw(15);
                    line-height: 2;
                    text-align: justify;
                    margin-top: _vw(18);
                }
            }
            @include _mq-down(sm) {
                flex-wrap: wrap;
                width: 100%;
                margin: _vw(30, 375) auto 0;
                .image-area {
                    width: 100%;
                }
                .text-area {
                    width: 100%;
                    margin-top: _vw(18, 375);
                    .role {
                        font-size: _vw(12, 375);
                    }
                    .name {
                        font-size: _vw(20, 375);
                        margin-top: _vw(10, 375);
                        span {
                            font-size: _vw(12, 375);
                        }
                    }
                    .work {
                        font-size: _vw(12, 375);
                        margin-top: _vw(10, 375);
                        line-height: 1.4;
                    }
                    .text {
                        font-size: _vw(12, 375);
                        line-height: 2;
                        text-align: justify;
                        margin-top: _vw(15, 375);
                    }
                }

            }
        }
    }
}

.sec-Method_02 {
    .manabi-content {
        width: _vw(1266);
        height: _vw(641);
        margin: 0 auto;
        padding-top: _vw(100);
        background: url("../../assets/img/method/bg_image_manabi.jpg") center center / 100% 100% no-repeat;
        @include _mq-down(sm) {
            width: 100%;
            height: _vw(190, 375);
            margin-top: _vw(50, 375);
            padding-top: 0;
            margin-bottom: _vw(10, 375);
        }
        .lead {
            font-size: _vw(40);
            line-height: 1.5;
            letter-spacing: 0.15em;
            padding-left: 0.15em;
            text-align: center;
            span {
                color: #c1272d;
            }
            @include _mq-down(sm) {
                font-size: _vw(22, 375);

            }
        }
    }
    .manabi-text {
        text-align: center;
        width: _vw(800);
        margin: _vw(-5) auto 0;
        span {
            display: inline-block;
        }
        @include _mq-down(sm) {
            text-align: justify;
            margin: _vw(-5, 375) auto 0;
            width: 100%;
            span {
                display: inline;
            }
        }
    }
    .voice-content {
        margin-top: _vw(140);
        .image {
            display: block;
            margin-top: _vw(25);
            width: _vw(1040);
            margin-left: _vw(-20);
        }
        @include _mq-down(sm) {
            margin-top: _vw(70, 375);
            .image {
                margin-top: _vw(20, 375);
                width: 100%;
                margin-left: 0;
            }
        }
    }
}

.sec-Method_03 {
    .practice-content {
        width: _vw(1266);
        height: _vw(513);
        margin: _vw(70) auto 0;
        background: url("../../assets/img/method/bg_image_practice_pc.jpg") center center / 100% 100% no-repeat;
        display: flex;
        align-items: center;
        justify-content: center;
        @include _mq-down(sm) {
            width: 100%;
            height: _vw(263, 375);
            margin: _vw(50, 375) auto 0;
            background-image: url("../../assets/img/method/bg_image_practice_sp.jpg");
        }
        .lead {
            font-size: _vw(34);
            line-height: 1.5;
            letter-spacing: 0.1em;
            //padding-left: 0.2em;
            padding-right: _vw(25);
            margin-top: _vw(-25);
            text-align: center;
            span {
                color: #c1272d;
            }
            @include _mq-down(sm) {
                font-size: _vw(20, 375);

            }
        }
    }
    .practice-text {
        text-align: center;
        width: _vw(800);
        margin: _vw(20) auto 0;
        span {
            display: inline-block;
        }
        @include _mq-down(sm) {
            text-align: justify;
            margin: _vw(15, 375) auto 0;
            width: _vw(335, 375);
            span {
                display: inline;
            }
        }
    }
    .shop-content {
        max-width: _vw(1066);
        margin: _vw(80) auto 0;
        @include _mq-down(sm) {
            max-width: _vw(335, 375);
            margin: _vw(60, 375) auto 0;
        }
        .container {
            margin-top: _vw(50);
            @include _mq-down(sm) {
                margin-top: _vw(30, 375);
            }
        }
        .container + .container {
            margin-top: _vw(60);
            @include _mq-down(sm) {
                margin-top: _vw(40, 375);
            }
        }
        .text-orange-title {
            font-size: _vw(32);
            font-weight: bold;
            color: $color-brand;
            text-align: center;
            letter-spacing: 0.15em;
            padding-left: 0.15em;
            @include _mq-down(sm) {
                font-size: _vw(20, 375);
            }
        }
        .flag {
            width: _vw(130);
            margin: 0 auto;
            //height: _vw(30);
            background: $color-brand;
            color: #fff;
            text-align: center;
            font-size: _vw(16);
            letter-spacing: 0.1em;
            padding: _vw(7);
            @include _mq-down(sm) {
                width: _vw(100, 375);
                font-size: _vw(12, 375);
                padding: _vw(7, 375);
            }
        }
        .shop-list {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin-top: _vw(20);
            li {
                img {
                    width: 100%;
                }
                p {
                    text-align: center;
                    margin-top: _vw(13);
                }
            }
            li + li {
                margin-left: _vw(23);
            }
            &.first li {
                width: _vw(340);
            }
            &.second li {
                width: _vw(567);
            }
            @include _mq-down(sm) {
                margin-top: _vw(20, 375);
                li {
                    img {
                        width: 100%;
                    }
                    p {
                        text-align: center;
                        margin-top: _vw(7, 375);
                    }
                }
                &.first li {
                    width: 90%;
                    & + li {
                        margin-top: _vw(20, 375);
                    }
                }
                &.second li {
                    width: 90%;
                }
            }
        }
    }
    .image-middle {
        margin: _vw(65) auto _vw(105);
        @include _mq-down(sm) {
            margin: _vw(40, 375) auto _vw(60, 375);
        }
    }
}

.Method-bnr {
    width: _vw(500);
    margin: _vw(140) auto 0;
    @include _mq-down(sm) {
        width: _vw(300, 375);
        margin: _vw(100, 375) auto 0;
    }
}

.Method-pager {
    width: _vw(400);
    margin: _vw(25) auto 0;
    display: flex;
    justify-content: space-between;
    .arrow {
        width: _vw(44);
        height: _vw(72);
        margin-top: _vw(25);
    }
    .center {
        width: _vw(280);
        text-align: center;
        margin: 0 auto;
        font-size: _vw(18);
        line-height: 1.3889;
        .num {
            font-size: _vw(26);
        }
        &.last {
            font-size: _vw(26);
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    @include _mq-down(sm) {
        width: _vw(300, 375);
        margin: _vw(25, 375) auto 0;
        .arrow {
            width: _vw(32, 375);
            height: _vw(54, 375);
            margin-top: _vw(15, 375);
        }
        .center {
            width: _vw(220, 375);
            text-align: center;
            margin: 0 auto;
            font-size: _vw(15, 375);
            line-height: 1.3889;
            .num {
                font-size: _vw(22, 375);
            }
        }
    }
}

.flex-box-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: _vw(40);
    &.two {
        img {
            display: block;
            width: _vw(475);
        }
    }
    &.three {
        margin-top: _vw(50);
        img {
            display: block;
            width: _vw(300);
        }
    }
    @include _mq-down(sm) {
        display: block;
        margin-top: _vw(30, 375);
        &.two {
            img {
                width: 100%;
            }
            img + img {
                margin-top: _vw(10, 375);
            }
        }
        &.three {
            margin-top: _vw(10, 375);
            img {
                width: 100%;
            }
            img + img {
                margin-top: _vw(10, 375);
            }
        }
    }

}

